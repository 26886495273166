import React from 'react';
import { AvatarProps } from './types';
import Status from '@primitives/Status';
import fallBackAvatar from '@assets/AvatarFallBackImg.svg';
import cx from 'classnames';
import styles from './Avatar.module.scss';
import {
    getAvatarSizeStyle,
    getAvatarVariantStyle,
    getAvatarWrapperStyle,
    getStatusWrapperStyle,
} from './utils';
import { DEFAULT_BACKGROUND_ICON, DEFAULT_BACKGROUND_LETTER } from './constants';

const Wrapper: React.FC<AvatarProps> = (props) => {
    const { isImage, children, ...restProps } = props;
    const wrapperEleType = isImage ? 'img' : 'div';
    return React.createElement(wrapperEleType, restProps, children);
};

const Avatar: React.FC<AvatarProps> = ({
    isImage = false,
    variant = 'circle',
    color,
    size,
    children,
    src,
    alt,
    style,
    className,
    sizeVariant = 'lg',
    status = 'noStatus',
    ...restProps
}) => {
    const avatarStyle = cx(
        styles.avatar,
        getAvatarVariantStyle(variant),
        getAvatarWrapperStyle(isImage),
        getAvatarSizeStyle(sizeVariant),
        className,
        {
            [styles['avatar--is-img']]: isImage,
        },
    );

    const getBgColorOfChild = () => {
        if (typeof children === 'string' || alt) return DEFAULT_BACKGROUND_LETTER;
        return DEFAULT_BACKGROUND_ICON;
    };
    const getDynamicStyles = () => {
        const dynamicStyles: React.CSSProperties = {
            width: size,
            height: size,
        };

        if (!isImage) dynamicStyles.backgroundColor = color || getBgColorOfChild();

        if (style) return { ...dynamicStyles, ...style };
        return dynamicStyles;
    };

    const getWrapperChildren = () => {
        if (isImage) return null;
        const childrenInString = children?.toString();
        if (childrenInString?.length) return children;
        if (alt) return alt[0];
        return null;
    };

    const renderStatus = (): JSX.Element | false => {
        const statusWrapperStyles = cx(
            styles['status-wrapper'],
            getStatusWrapperStyle(sizeVariant),
        );

        return (
            variant === 'circle' &&
            status !== 'noStatus' && (
                <div className={statusWrapperStyles}>
                    <Status variant={status ? 'green' : 'offline'} />
                </div>
            )
        );
    };

    return (
        <div className={styles['avatar-container']}>
            <Wrapper
                className={avatarStyle}
                style={getDynamicStyles()}
                isImage={isImage}
                src={src || fallBackAvatar}
                {...restProps}
            >
                {getWrapperChildren()}
            </Wrapper>
            {renderStatus()}
        </div>
    );
};

export default React.memo(Avatar);
