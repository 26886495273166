import React from 'react';
import Typography from '@primitives/Typography';
import styles from './SelectSearchResultList.module.scss';
import { SelectSearchResultListProps } from './types';

const SelectSearchResultList: React.FC<SelectSearchResultListProps> = ({
    results,
    onOptionSelect,
}) => {
    return (
        <div className={styles['select-search-result-list-wrapper']}>
            {results.map((resultItem, index) => {
                const { label } = resultItem;
                return (
                    <div
                        key={label + index}
                        className={styles['result-item']}
                        onClick={() => onOptionSelect(index)}
                    >
                        <Typography variant="p2">{label}</Typography>
                    </div>
                );
            })}
        </div>
    );
};

export default SelectSearchResultList;
