import { TabItemKey, TabItemProps, TabsChild, TabsProps } from '@components/Tabs/types';
import TabPanel from '@primitives/TabPanel';
import React, { cloneElement, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import classes from './Tabs.module.scss';
import TabItem from '@components/Tabs/TabItem';
import { TAB_DEFAULT_ORIENTATION, TAB_DEFAULT_VARIANT } from '@components/Tabs/constants';

const Tabs: React.FC<TabsProps> = ({
    variant = TAB_DEFAULT_VARIANT,
    orientation = TAB_DEFAULT_ORIENTATION,
    defaultSelectedKey,
    children,
    contentClassName,
    containerClassName,
    tabsClassName,
    onTabChange,
    selectedTabKey,
    textOverflowWrap = false,
    tabPanelLabelTypoVariant,
}) => {
    // TODO: Better to use id instead of key for identifying an item
    // Reason: React manipulates the key to track to element in the virtual dom which makes it hard to use it directly
    // Fix this.

    const normalizedChildren: TabsChild[] = [];
    React.Children.forEach(children, (child) => {
        normalizedChildren.push(cloneElement(child));
    });
    const tabsWrapperClasses = cx(
        classes['tabs-wrapper'],
        classes[orientation],
        containerClassName,
    );
    const tabVariant =
        variant === 'sideline' ? (orientation === 'vertical' ? variant : 'underline') : variant;
    const tabsClasses = cx(classes.tabs, classes[orientation], classes[tabVariant], tabsClassName);
    const contentClasses = cx(classes.content, contentClassName);

    const [selectedKey, setSelectedKey] = useState<TabItemKey>(
        defaultSelectedKey || normalizedChildren[0].key || 0,
    );

    const [contentMap, setContentMap] = useState<Map<TabItemKey, ReactNode>>();

    useEffect(() => {
        if (selectedTabKey) setSelectedKey(selectedTabKey as TabItemKey);
    }, [selectedTabKey]);

    const tabPanelItemProps = useMemo(() => {
        const newContentMap = new Map();
        const tabPanelItemProps = normalizedChildren.map(
            (child: ReactElement<TabItemProps, typeof TabItem>) => {
                if (React.isValidElement<TabItemProps>(child)) {
                    if (child.type.displayName === 'TabItem') {
                        const { key, props } = child;
                        const { children, ...restProps } = props;
                        newContentMap.set(key, children);
                        return {
                            ...restProps,
                            key: String(key),
                        };
                    } else {
                        throw new Error('The children in Tabs component must be of type TabItem');
                    }
                } else {
                    // TODO: Need to decide the error message
                    throw new Error('Not a valid element');
                }
            },
        );
        setContentMap(newContentMap);
        return tabPanelItemProps;
    }, [children]);

    const tabPanelClickHandler = (key: TabItemKey, e: React.MouseEvent<HTMLButtonElement>) => {
        setSelectedKey(key || 0);
        if (onTabChange) onTabChange(key, e);
    };

    const tabPanels = useMemo(() => {
        return tabPanelItemProps.map((p: Omit<TabItemProps, 'children'>, i) => {
            const { key, ...restProps } = p;
            let active: boolean;
            if (selectedKey !== null && selectedKey !== undefined) {
                active = selectedKey === key;
            } else {
                active = i === 0;
                setSelectedKey(key);
            }
            return (
                <TabPanel
                    {...restProps}
                    key={key}
                    active={active}
                    tabPanelClickHandler={(e) => {
                        tabPanelClickHandler(key, e);
                    }}
                    fullWidth={orientation === 'vertical'}
                    variant={tabVariant}
                    // TODO: Changes required here for supporting horizontal text overflow.
                    textOverflowWrap={textOverflowWrap && orientation === 'vertical'}
                    tabPanelLabelTypoVariant={tabPanelLabelTypoVariant}
                />
            );
        });
    }, [tabPanelItemProps, selectedKey, tabVariant]);

    return (
        <div className={tabsWrapperClasses}>
            <div className={tabsClasses} role="tab">
                {tabPanels}
            </div>
            <div className={contentClasses}>{contentMap?.get(selectedKey)}</div>
        </div>
    );
};

Tabs.displayName = 'Tabs';

Tabs.defaultProps = {
    variant: TAB_DEFAULT_VARIANT,
    orientation: TAB_DEFAULT_ORIENTATION,
};

export default Tabs;
