import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const DeleteIconOutlined: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={svgWidth}
            height={svgHeight}
            fill="none"
            viewBox="0 0 24 24"
            {...restProps}
        >
            <mask
                id="mask0_3809_139198"
                style={{ maskType: 'alpha' }}
                width="24"
                height="24"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_3809_139198)">
                <path
                    fill={color}
                    d="M7 21c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 015 19V6a.97.97 0 01-.713-.287A.97.97 0 014 5a.97.97 0 01.287-.713A.97.97 0 015 4h4c0-.283.096-.521.288-.713A.967.967 0 0110 3h4a.97.97 0 01.713.287A.97.97 0 0115 4h4c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 01-.288.713A.967.967 0 0119 6v13c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0117 21H7zM7 6v13h10V6H7zm2 10c0 .283.096.52.288.712A.965.965 0 0010 17a.968.968 0 00.713-.288A.967.967 0 0011 16V9a.97.97 0 00-.287-.713A.97.97 0 0010 8a.967.967 0 00-.712.287A.968.968 0 009 9v7zm4 0c0 .283.096.52.288.712A.965.965 0 0014 17a.968.968 0 00.713-.288A.967.967 0 0015 16V9a.97.97 0 00-.287-.713A.97.97 0 0014 8a.967.967 0 00-.712.287A.968.968 0 0013 9v7z"
                ></path>
            </g>
        </svg>
    );
};

export default React.memo(DeleteIconOutlined);
