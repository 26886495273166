import styles from './BorderedInput.module.scss';
import {
    inputStates,
    BorderedInputClasses,
    HelperTextVariants,
    inputSizeToFontType,
    otherTextToFontType,
} from './constants';
import { BorderedVariantType, HelperTextVariantType, BorderedInputSizeVariant } from './types';
import { FontType } from '@primitives/Typography/types';

export const getBorderedVariantClass = (variant: BorderedVariantType = 'default') => {
    return styles[`${BorderedInputClasses.wrapper}--${variant}`];
};

export const getInputStatus = (
    success: HelperTextVariantType,
    error: HelperTextVariantType,
    disabled: boolean | undefined,
): BorderedVariantType => {
    if (disabled) {
        return inputStates.disabled;
    }

    if (success) {
        return HelperTextVariants.success;
    }

    if (error) {
        return HelperTextVariants.error;
    }

    return inputStates.default;
};

export const getHelperTextVariantClass = (
    success: HelperTextVariantType,
    error: HelperTextVariantType,
) => {
    if (success) {
        return styles[`${BorderedInputClasses.helperTextWrapper}--${HelperTextVariants.success}`];
    }
    if (error) {
        return styles[`${BorderedInputClasses.helperTextWrapper}--${HelperTextVariants.error}`];
    }
    return '';
};

export const getInputSizeTypographyVariant = (
    size: BorderedInputSizeVariant = 'small',
): FontType => {
    return inputSizeToFontType[size];
};

export const getTextTypographyVariant = (size: BorderedInputSizeVariant = 'small'): FontType => {
    return otherTextToFontType[size];
};

export const getInputSizeClass = (size: BorderedInputSizeVariant = 'small') => {
    return styles[`${BorderedInputClasses.wrapper}--${size}`];
};

export const getAdornmentClass = (
    adornmentClass: BorderedInputClasses,
    variant: BorderedVariantType = 'default',
) => {
    return styles[`${adornmentClass}--${variant}`];
};
