import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const EditIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            width={svgWidth}
            height={svgHeight}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <mask
                id="mask0_3746_139214"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3746_139214)">
                <path
                    d="M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM4 21C3.71667 21 3.47933 20.904 3.288 20.712C3.096 20.5207 3 20.2833 3 20V17.175C3 17.0417 3.025 16.9127 3.075 16.788C3.125 16.6627 3.2 16.55 3.3 16.45L13.6 6.15L17.85 10.4L7.55 20.7C7.45 20.8 7.33767 20.875 7.213 20.925C7.08767 20.975 6.95833 21 6.825 21H4Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default React.memo(EditIcon);
