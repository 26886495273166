import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const ArrowRightIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            width={svgWidth}
            height={svgHeight}
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.68037 1.34232L0.674541 4.34815C0.221926 4.80077 0.221926 5.53192 0.674541 5.98453L3.68037 8.99036C4.41152 9.72151 5.66492 9.19926 5.66492 8.16637V2.15471C5.66492 1.12182 4.41152 0.611175 3.68037 1.34232Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(ArrowRightIcon);
