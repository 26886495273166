import { IconSize } from '@icons/types';
import styles from './IconButton.module.scss';
import { IconButtonSizes } from './types';

export const variantIconColorMap = {
    primary: styles.primaryIconColor,
    secondary: styles.secondaryIconColor,
    'borderless-primary': styles.borderlessPrimaryIconColor,
    'borderless-secondary': styles.borderlessSecondaryIconColor,
};

export const sizeToIconSizeMap: Record<IconButtonSizes, IconSize> = {
    small: 'xs',
    medium: 'sm',
    large: 'md',
};
