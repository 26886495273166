import { Toast, ToastItem, ToastItemStatus } from '../types';

export function toToastItem(toast: Toast, status: ToastItemStatus): ToastItem {
    return {
        content: toast.content as any,
        containerId: toast.props.containerId,
        id: toast.props.toastId,
        isColored: toast.props.isColored,
        type: toast.props.type,
        data: toast.props.data || {},
        isLoading: toast.props.isLoading,
        icon: toast.props.icon as any,
        status,
    };
}
