import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import Button from '@primitives/Button';
import Checkbox from '@primitives/Checkbox';
import Chip from '@primitives/Chip';
import CloseIcon from '@icons/CloseIcon';
import { AllOption } from '@components/MobileMultiSelect/constants';
import SearchBarInput from '@components/SearchBarInput';
import Typography from '@primitives/Typography';

import styles from './SelectPicker.module.scss';
import { SelectPickerOptionProps, SelectPickerProps, SelectPickerValue } from './types';

const SelectPicker: React.FC<SelectPickerProps> = ({
    options,
    onSelectionChange,
    selectedValues,
    selectedValue,
    multiSelect = false,
    showSearchBar = false,
    showSelectedOptionChips = false,
    handleCancelClick,
    handleApplyClick,
    wrapperClasses,
    hideBottomCTAs = false,
    selectPickerItemClassName = '',
    placeholder,
    clearAll = false,
    searchBarClass = '',
    showSelectAll = false,
    selectAllClass,
    selectAllSubtext = '',
    pickerCheckboxClass = '',
    updateSelectedOptions,
    ...restProps
}) => {
    const [optionsToDisplay, setOptionsToDisplay] = useState<SelectPickerOptionProps[]>([]);
    const [selectedValuesMap, setSelectedValuesMap] = useState(
        new Map<SelectPickerValue, SelectPickerOptionProps>([]),
    );
    const [searchError, setSearchError] = useState<string | false>(false);
    const searchIpRef = useRef<HTMLInputElement>(null);
    const [selectAllState, setSelectAllState] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');

    /**
     *
     * Why ref ?
     * When we try to access the options in handleSearchChange it was referring the first prop value of options due to stale closure formed by handleSearchChange at the beginning.
     * This is a very common issue in react.
     */
    const optionsRef = useRef<SelectPickerOptionProps[]>([]);
    optionsRef.current = options;

    useEffect(() => {
        handleSearchChange(searchIpRef.current?.value || '');
    }, [options]);

    useEffect(() => {
        if (!multiSelect || !selectedValues) {
            return;
        }
        const selectedOptionsMap = new Map(
            optionsToDisplay.map((option) => [option.value, option]),
        );
        const updatedSelectedValuesMap = new Map<SelectPickerValue, SelectPickerOptionProps>([]);
        selectedValues.forEach((value) => {
            if (selectedOptionsMap.has(value)) {
                const option = selectedOptionsMap.get(value);
                if (option) updatedSelectedValuesMap.set(value, option);
            }
        });
        setSelectedValuesMap(new Map(updatedSelectedValuesMap));
    }, [selectedValues, multiSelect, optionsToDisplay]);

    useEffect(() => {
        if (multiSelect || !selectedValue) {
            return;
        }
        const selectedOption = optionsToDisplay.find((opt) => opt?.value === selectedValue);
        if (selectedOption) {
            addSelectedValue(selectedValue, selectedOption);
        }
    }, [selectedValue, optionsToDisplay, multiSelect]);

    useEffect(() => {
        if (
            multiSelect &&
            showSelectAll &&
            selectedValues?.length &&
            selectedValuesMap?.size &&
            selectedValues.length === selectedValuesMap.size &&
            selectedValues.length === optionsToDisplay.length &&
            !selectAllState
        ) {
            setSelectAllState(true);
        }
    }, [showSelectAll, multiSelect, selectedValues, selectedValuesMap, optionsToDisplay]);

    useEffect(() => {
        if (clearAll) {
            setSelectedValuesMap(new Map([]));
            onSelectionChange([]);
            setSelectAllState(false);
        }
    }, [clearAll]);

    const addSelectedValue = (value: SelectPickerValue, option: SelectPickerOptionProps) => {
        setSelectedValuesMap(new Map(selectedValuesMap.set(value, option)));
    };

    const handleSingleSelect = (value: SelectPickerValue, option: SelectPickerOptionProps) => {
        if (selectedValuesMap.has(value)) {
            selectedValuesMap.delete(value);
            setSelectedValuesMap(new Map(selectedValuesMap));
        } else {
            setSelectedValuesMap(new Map([[value, option]]));
        }
        onSelectionChange(option);
    };

    const handleMultiSelect = (value: SelectPickerValue, option: SelectPickerOptionProps) => {
        // check map => if value already present then delete else add
        if (selectedValuesMap.has(value)) {
            selectedValuesMap.delete(value);
            setSelectedValuesMap(new Map(selectedValuesMap));
        } else {
            addSelectedValue(value, option);
        }

        // create array of map value
        const newSelectedList = Array.from(selectedValuesMap.values());
        onSelectionChange(newSelectedList);
    };

    const onSelectionChangeHandler = (option: SelectPickerOptionProps) => {
        const { value, isDisabled } = option;

        // nothing if disabled
        if (isDisabled) {
            return;
        }

        multiSelect ? handleMultiSelect(value, option) : handleSingleSelect(value, option);
    };

    const handleSearchChange = (searchValue: string): void => {
        if (!optionsRef?.current?.length) return;
        setSearchValue(searchValue);
        const filteredOptions = optionsRef.current.filter((option) =>
            option.label.toLowerCase().includes(searchValue.toLowerCase()),
        );

        if (filteredOptions.length === 0) setSearchError('No results found!');
        else setSearchError(false);

        setOptionsToDisplay(filteredOptions);
    };

    const renderSelectAll = (): JSX.Element | false => {
        return (
            multiSelect &&
            showSelectAll &&
            !searchValue?.length && (
                <div className={cx(styles['select-all-container'], selectAllClass)}>
                    <Checkbox
                        checked={selectAllState}
                        onClick={() => handleSelectAll()}
                        readOnly
                        role="checkbox"
                        data-testid="select-all-checkbox"
                    />
                    <Button
                        variant="text"
                        className={styles['select-all-label']}
                        onClick={(e) => e?.stopPropagation()}
                    >
                        Select All
                        <span className={styles['select-all-custom-msg']}>{`(${
                            optionsToDisplay?.length
                        }${selectAllSubtext ? ` ${selectAllSubtext}` : ''})`}</span>
                    </Button>
                </div>
            )
        );
    };

    const renderSearchBar = (): JSX.Element | false => {
        return (
            (showSearchBar || showSelectAll) && (
                <header className={styles['select-all-and-search-header']} role="searchbox">
                    {showSearchBar && (
                        <div className={cx(styles['search-container'], searchBarClass)}>
                            <SearchBarInput
                                debounceDelay={200}
                                onSearchChange={handleSearchChange}
                                inputSize="medium"
                                fullWidth
                                error={searchError}
                                placeholder={placeholder}
                            />
                        </div>
                    )}
                    {renderSelectAll()}
                </header>
            )
        );
    };

    const handleSelectAll = (undoSelectAll = false): void => {
        const handleUpdateSelectAllCallback = (
            optionsMap: Map<SelectPickerValue, SelectPickerOptionProps>,
        ): void => {
            // create array of map value
            const newSelectedList = Array.from(optionsMap.values());
            if (updateSelectedOptions) {
                updateSelectedOptions(newSelectedList);
            }
        };

        const updateSelectedValueMap = (): void => {
            const newSelectedValueMap = new Map<SelectPickerValue, SelectPickerOptionProps>([]);

            options?.forEach((option) => {
                newSelectedValueMap.set(option?.value, option);
            });

            setSelectedValuesMap(new Map(newSelectedValueMap));
            /** check the callback and pass the all selected option map */
            handleUpdateSelectAllCallback(newSelectedValueMap);
        };

        setSelectAllState(!selectAllState);

        /** Remove all selected values */
        if (undoSelectAll) {
            setSelectedValuesMap(new Map([]));
            return;
        }

        const selectedValuesArr = Array.from(selectedValuesMap.values());
        if (selectedValuesArr?.length === optionsToDisplay?.length) {
            setSelectedValuesMap(new Map([]));
        } else {
            updateSelectedValueMap();
        }
    };

    const renderChips = (): JSX.Element | false => {
        const selectedValuesArr = Array.from(selectedValuesMap.values());
        const renderChipComponents =
            multiSelect && showSelectedOptionChips && selectedValuesArr.length > 0;

        if (
            showSelectedOptionChips &&
            showSelectAll &&
            selectedValuesArr.length === optionsToDisplay?.length &&
            !searchValue?.length
        ) {
            return (
                <section className={styles['selection-chips']}>
                    <Chip
                        label={`${AllOption.label} (${optionsToDisplay?.length})`}
                        RightAdornment={<CloseIcon />}
                        onChipClick={() => handleSelectAll(true)}
                        key={AllOption.value}
                    />
                </section>
            );
        }

        return (
            renderChipComponents && (
                <section className={styles['selection-chips']}>
                    {selectedValuesArr.map((option) => {
                        return (
                            <Chip
                                label={option.label}
                                RightAdornment={<CloseIcon />}
                                onChipClick={() => onSelectionChangeHandler(option)}
                                key={option.label}
                            />
                        );
                    })}
                </section>
            )
        );
    };

    const handleApplyButtonClick = (): void => {
        if (handleApplyClick) handleApplyClick(Array.from(selectedValuesMap.values()));
    };

    const selectAllWrapperClasses: string =
        (multiSelect && showSelectAll) || showSearchBar ? styles['select-all-wrapper'] : '';

    return (
        <div
            className={cx(styles['selectPicker'], selectAllWrapperClasses, wrapperClasses)}
            {...restProps}
            role="select"
        >
            {renderSearchBar()}
            {renderChips()}
            {optionsToDisplay.map((option) => {
                const selected = selectedValuesMap.has(option?.value);
                return (
                    <div
                        key={`select-picker-item-${option?.value}`}
                        className={cx(
                            styles['selectPicker--item'],
                            {
                                [styles['selectPicker--selected']]: selected,
                                [styles['selectPicker--disabled']]: option?.isDisabled,
                            },
                            selectPickerItemClassName,
                        )}
                        onClick={() => onSelectionChangeHandler(option)}
                        role="option"
                    >
                        {multiSelect ? (
                            <Checkbox
                                title={option?.label}
                                disabled={option?.isDisabled}
                                checked={selected}
                                onClick={(e) => e.stopPropagation()}
                                readOnly
                                role="checkbox"
                                checkboxOuterWrapperClass={pickerCheckboxClass}
                            />
                        ) : (
                            <Typography variant="p3">{option?.label}</Typography>
                        )}
                    </div>
                );
            })}
            {multiSelect && !hideBottomCTAs && (
                <footer className={styles['multiselect-footer']} role="footer">
                    <Button variant="secondary" size="small" fullWidth onClick={handleCancelClick}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        fullWidth
                        onClick={handleApplyButtonClick}
                        disabled={selectedValuesMap.size === 0}
                    >
                        Apply
                    </Button>
                </footer>
            )}
        </div>
    );
};

export default SelectPicker;
