import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const ArrowRightUpIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}): JSX.Element => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={svgWidth}
            height={svgHeight}
            fill="none"
            viewBox="0 0 20 20"
            {...restProps}
        >
            <path
                fill={color}
                d="M7.5 5a.83.83 0 00.833.833h4.659l-9.075 9.075a.83.83 0 101.175 1.175l9.075-9.075v4.659c0 .458.375.833.833.833a.836.836 0 00.833-.833V5A.836.836 0 0015 4.167H8.333A.836.836 0 007.5 5z"
            ></path>
        </svg>
    );
};

export default React.memo(ArrowRightUpIcon);
