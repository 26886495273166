import React from 'react';
import cx from 'classnames';

import styles from './styles/GridColumn.module.scss';
import { GridColumnProps } from '../types';
import { getGridColClass } from '../utils';
import { GridColAttributes, GridColPrefix, gridLayoutValidation } from '../constants';
import { useGridRAF } from '../../../hooks/useRAFhook';

const GridColumn: React.FC<GridColumnProps> = ({
    className = '',
    children,
    xs = false,
    sm = false,
    md = false,
    lg = false,
    style,
    ...otherProps
}) => {
    const containerColRef = React.useRef<HTMLDivElement>(null);

    useGridRAF(containerColRef, gridLayoutValidation.gridColumn, true);

    const dynamicClass = cx(
        styles[`${GridColPrefix}`],
        getGridColClass(xs, GridColAttributes.XS),
        getGridColClass(sm, GridColAttributes.SM),
        getGridColClass(md, GridColAttributes.MD),
        getGridColClass(lg, GridColAttributes.LG),
        className,
    );

    return (
        <div style={style} className={dynamicClass} ref={containerColRef} {...otherProps}>
            {children}
        </div>
    );
};

export default GridColumn;
