import Counter from '@primitives/Counter';
import { TabPanelProps } from '@primitives/TabPanel/types';
import React, { useMemo } from 'react';
import classes from './TabPanel.module.scss';
import cx from 'classnames';
import { CounterColorVariants, CounterProps } from '@primitives/Counter/types';
import {
    DEFAULT_TAB_COUNTER_COLOR,
    TAB_ACTIVE_COUNTER_COLOR,
    TAB_COUNTER_COLOR_MAP,
    TAB_PANEL_DEFAULT_VARIANT,
} from '@primitives/TabPanel/constants';
import Typography from '@primitives/Typography';

const TabPanel = React.forwardRef<HTMLButtonElement, TabPanelProps>(
    (
        {
            variant,
            active,
            label,
            tabPanelClickHandler,
            startAdornment,
            endAdornment,
            counter,
            fullWidth,
            state,
            textOverflowWrap,
            tabPanelLabelTypoVariant,
        },
        ref,
    ) => {
        const renderTabPanelLeftContent = useMemo(() => {
            const sideLineTabs = variant === 'sideline';
            const finalVariant = tabPanelLabelTypoVariant
                ? tabPanelLabelTypoVariant
                : sideLineTabs
                ? 'p5'
                : 'p3';
            const labelComp = (
                <Typography
                    as="span"
                    variant={finalVariant}
                    className={cx(classes.label, {
                        [classes['label-allow-overflow']]: textOverflowWrap,
                        [classes['sideline-label-styles']]: sideLineTabs,
                    })}
                >
                    {label}
                </Typography>
            );
            if (!startAdornment) return labelComp;
            return (
                <>
                    <span className={classes['start-icon']}>{startAdornment}</span>
                    {labelComp}
                </>
            );
        }, [startAdornment, label, tabPanelLabelTypoVariant]);

        const renderTabPanelRightContent = useMemo(() => {
            let counterColor: CounterColorVariants = DEFAULT_TAB_COUNTER_COLOR;
            if (state) counterColor = TAB_COUNTER_COLOR_MAP[state];
            if (active) counterColor = TAB_ACTIVE_COUNTER_COLOR;

            const counterProps: CounterProps = {
                color: variant === 'sideline' ? 'blue-v2' : counterColor,
                size: 'sm',
                value: counter,
            };

            const counterComp = (
                <span className={classes.counter}>
                    <Counter {...counterProps} />
                </span>
            );

            if (!endAdornment && !counter) return null;
            if (!endAdornment) return counterComp;
            if (counter === undefined || counter === null)
                return <span className={classes['end-icon']}>{endAdornment}</span>;
            return (
                <>
                    {counterComp}
                    <span className={classes['end-icon']}>{endAdornment}</span>
                </>
            );
        }, [endAdornment, counter, state, active]);

        const tabpanelClasses = cx(
            classes.tabpanel,
            { [classes.active]: active },
            { [classes['full-width']]: fullWidth },
            variant && classes[variant],
            state && classes[state],
        );

        return (
            <button
                ref={ref}
                role="tabpanel"
                className={tabpanelClasses}
                onClick={tabPanelClickHandler}
            >
                <span className={classes.left}>{renderTabPanelLeftContent}</span>
                <span className={classes.right}>{renderTabPanelRightContent}</span>
            </button>
        );
    },
);

TabPanel.displayName = 'TabPanel';

TabPanel.defaultProps = {
    variant: TAB_PANEL_DEFAULT_VARIANT,
};

export default React.memo(TabPanel);
