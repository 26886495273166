import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const AlertIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={svgWidth}
            height={svgHeight}
            fill="none"
            viewBox="0 0 24 24"
            {...restProps}
        >
            <mask
                id="mask0_3269_138127"
                style={{ maskType: 'alpha' }}
                width="24"
                height="24"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_3269_138127)">
                <path
                    fill={color}
                    d="M12 17a.968.968 0 00.713-.288A.967.967 0 0013 16a.97.97 0 00-.287-.713A.97.97 0 0012 15a.967.967 0 00-.712.287A.968.968 0 0011 16c0 .283.096.52.288.712A.965.965 0 0012 17zm-1-5a1 1 0 102 0V8a1 1 0 10-2 0v4zm1 10a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 012 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0112 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0122 12a9.733 9.733 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0112 22z"
                ></path>
            </g>
        </svg>
    );
};

export default React.memo(AlertIcon);
