import React from 'react';
import cx from 'classnames';
import Typography from '@primitives/Typography';
import { KeyValueLabelProps } from './types';
import styles from './KeyValueLabel.module.scss';

const KeyValueLabel: React.FC<KeyValueLabelProps> = ({ dataArray = [], className = '', style }) => {
    return (
        <div
            className={cx(styles['key-value-wrapper'], className)}
            style={style}
            data-testid="wrapper"
        >
            {dataArray.map((row) => {
                const { key, value } = row;
                return (
                    <div className={styles['key-value-row']} key={key} data-testid="row">
                        <Typography variant="p3" as="span" className={styles['key-text']}>
                            {key}
                        </Typography>
                        <Typography variant="h4" as="span" className={styles['value-text']}>
                            {value}
                        </Typography>
                    </div>
                );
            })}
        </div>
    );
};

export default KeyValueLabel;
