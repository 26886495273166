import { IconProps } from '@icons/types';
import React, { ReactNode } from 'react';

// these are the props that can be passed to both, the accordion or the accordionGroup, hence the name
// 'generic'
export interface GenericAccordionProps {
    containerClassName?: string;
    headerClassName?: string;
    headerArrowClassName?: string;
    bodyClassName?: string;
    variant?: AccordionVariant;
}

export interface AccordionGroupProps
    extends React.ComponentPropsWithoutRef<'div'>,
        GenericAccordionProps {
    className?: string;
    spaced?: boolean;
}

export interface AccordionProps
    extends React.ComponentPropsWithoutRef<'div'>,
        GenericAccordionProps {
    header: ReactNode;
    children: ReactNode;
    expanded?: boolean;
    onToggledExpansion?: (isExpanded: boolean) => void;
    chevronIconProps?: IconProps;
}

export enum AccordionVariant {
    'BORDERED' = 'BORDERED',
    'UNDERLINED' = 'UNDERLINED',
}
