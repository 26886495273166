import React from 'react';
import { IconProps } from '../types';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const NaviNewLogoIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = '#FA4F56',
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            width={svgWidth}
            height={svgHeight}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path
                d="M23.275 18.6178C22.4566 17.8349 21.3671 17.3994 20.2355 17.3994H4V43.9994H13V25.9994H22V40.9994C22 42.656 23.3434 43.9994 25 43.9994H31V27.881C31 26.6797 30.5092 25.5297 29.6395 24.6994L23.275 18.6178V18.6178Z"
                fill="#22D081"
            />
            <path d="M22.0005 4V12.6H35.4005V26H44.0005V4H22.0005Z" fill="#22D081" />
        </svg>
    );
};

export default React.memo(NaviNewLogoIcon);
