import React from 'react';
import cx from 'classnames';

import styles from './Card.module.scss';
import { CardProps } from './types';

const Card: React.FC<CardProps> = ({ className, children, ...restProps }) => {
    return (
        <div className={cx(styles.card, className)} {...restProps}>
            {children}
        </div>
    );
};

export default Card;
