import React from 'react';
import { PopupNavItemProps } from './types';
import commonStyles from '../Navbar.module.scss';
import styles from './PopupNavItem.module.scss';
import Typography from '@primitives/Typography';
import { getNavItemLabelColor } from '../utils';
import cx from 'classnames';
import Count from '@primitives/Counter/Counter';

const PopupNavItem: React.FC<PopupNavItemProps> = (props) => {
    const { navItem, index, open, handleToggle, handleCollapseNavbar } = props;
    const { Icon, label, popupContent, popupContentWrapperClassName, count } = navItem;

    const contentColor = getNavItemLabelColor(false);

    const handleBackdropClick = () => {
        handleToggle();
        handleCollapseNavbar();
    };

    return (
        <>
            <li
                key={label + index}
                data-testid="popup-nav-item"
                className={cx(commonStyles['nav-item'], {
                    [commonStyles['nav-item--active']]: open,
                    [styles['popup-nav-item--active']]: open,
                })}
                onClick={handleToggle}
            >
                <div className={commonStyles['nav-item-content']}>
                    <Icon size="md" color={contentColor} />
                    <Typography
                        variant="p3"
                        color={contentColor}
                        className={commonStyles['nav-item__label']}
                    >
                        {label}
                    </Typography>
                    {!!count && <Count overflowCount={9} value={count} color="red" size="sm" />}
                </div>
            </li>
            {open && (
                <div className={styles['popup-wrapper']} onClick={handleBackdropClick}>
                    <div
                        className={cx(
                            styles['popup-content-wrapper'],
                            popupContentWrapperClassName,
                        )}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {popupContent}
                    </div>
                </div>
            )}
        </>
    );
};

export default PopupNavItem;
