export const MobileMultiSelectConstants = {
    moreCTA: 'more',
    clearAllCTA: 'Clear All',
    applyCTA: 'Apply',
};

export const AllOption = {
    value: 'all',
    label: 'All',
    id: 'all',
};
