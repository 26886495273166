import React from 'react';
import { ButtonProps } from './types';
import cx from 'classnames';
import styles from './Button.module.scss';
import { getButtonSizeClass, getButtonVariantClass, getTypographyVariant } from './utils';
import Typography from '@primitives/Typography';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant = 'primary',
            children,
            size = 'medium',
            className = '',
            fullWidth = false,
            disabled = false,
            startAdornment,
            endAdornment,
            ...restProps
        },
        ref,
    ) => {
        const btnClass = cx(
            styles.btn,
            getButtonVariantClass(variant),
            getButtonSizeClass(size),
            { [styles['btn--full-width']]: fullWidth },
            className,
        );

        const getAdornment = (Adornment: React.ReactNode) => {
            if (typeof Adornment === 'string' && Adornment !== '')
                return (
                    <Typography variant={getTypographyVariant(size)} as="span" color="inherit">
                        {Adornment}
                    </Typography>
                );
            if (Adornment) return Adornment;
            return null;
        };

        return (
            <button ref={ref} className={btnClass} disabled={disabled} {...restProps}>
                <>
                    {getAdornment(startAdornment)}
                    <Typography variant={getTypographyVariant(size)} as="span" color="inherit">
                        {children}
                    </Typography>
                    {getAdornment(endAdornment)}
                </>
            </button>
        );
    },
);

export default Button;
