import { getCounterColorVariantClass, getCounterSizeVariantClass } from '@primitives/Counter/utils';

import { CounterProps, CounterTypographyVariants } from '@primitives/Counter/types';
import React, { useMemo } from 'react';
import classes from './Counter.module.scss';
import cx from 'classnames';
import Typography from '@primitives/Typography';
import { DEFAULT_COUNTER_COLOR, DEFAULT_COUNTER_SIZE } from '@primitives/Counter/constants';

const Count: React.FC<CounterProps> = ({
    size = DEFAULT_COUNTER_SIZE,
    color,
    value = 0,
    overflowCount = 999,
    counterClassName,
}) => {
    const counterClasses = cx(
        classes.counter,
        getCounterColorVariantClass(color),
        getCounterSizeVariantClass(size),
        counterClassName,
    );

    const formatValue = (val: number) => val.toLocaleString();

    const renderValue = useMemo(() => {
        if (value <= overflowCount) return formatValue(value);
        return `${formatValue(overflowCount)}+`;
    }, [value, overflowCount]);

    return (
        <Typography
            role="status"
            as="span"
            variant={CounterTypographyVariants[size]}
            className={counterClasses}
        >
            {renderValue}
        </Typography>
    );
};

Count.displayName = 'Count';

Count.defaultProps = {
    size: DEFAULT_COUNTER_SIZE,
    color: DEFAULT_COUNTER_COLOR,
    value: 0,
    overflowCount: 999,
};

export default Count;
