import React from 'react';
import cx from 'classnames';

import Typography from '@primitives/Typography';
import styles from './BorderedInput.module.scss';
import RequiredIcon from '@icons/RequiredIcon';
import { BorderedInputClasses, inputStates } from './constants';
import { BorderedInputProps, BorderedVariantType } from './types';
import {
    getBorderedVariantClass,
    getInputStatus,
    getHelperTextVariantClass,
    getInputSizeTypographyVariant,
    getTextTypographyVariant,
    getInputSizeClass,
    getAdornmentClass,
} from './utils';

const BorderedInput = React.forwardRef<HTMLInputElement, BorderedInputProps>(
    (
        {
            containerClassName = '',
            LeftInputAdornment = '',
            RightInputAdornment = '',
            hintMsg = '',
            hintMsgIcon: HintMsgIcon = null,
            inputLabel = '',
            inputLabelIcon: LabelIcon = null,
            Icon = null,
            success = false,
            error = false,
            value = '',
            disabled = false,
            required,
            placeholder = '',
            inputSize = 'medium',
            fullWidth = false,
            onFocus,
            onBlur,
            onChange,
            labelClassName = '',
            helperTextClasses,
            hintTextClasses,
            ...restProps
        },
        ref,
    ) => {
        const finalInputStatus = getInputStatus(success, error, disabled);

        const [inputStatus, setInputStatus] = React.useState<BorderedVariantType>(finalInputStatus);
        const [inputValue, setInputValue] = React.useState(value);

        React.useEffect(() => {
            const status = getInputStatus(success, error, disabled);
            setInputStatus(status);
        }, [success, error, disabled]);

        React.useEffect(() => {
            setInputValue(value);
        }, [value]);

        const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
            if (inputStatus === inputStates.active) {
                setInputStatus(finalInputStatus);
            }

            /* To pass the current event to the consumer */
            if (onBlur) {
                onBlur(event);
            }
        };

        const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
            if (inputStatus !== inputStates.disabled) {
                setInputStatus(inputStates.active);
            }

            /* To pass the current event to the consumer */
            if (onFocus) {
                onFocus(event);
            }
        };

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;
            setInputValue(value);
            /* To pass the current event to the consumer */
            if (onChange) {
                onChange(event);
            }
        };

        const getLabelComponent = (): JSX.Element => {
            if (inputLabel !== '') {
                return (
                    <div className={styles[`${BorderedInputClasses.labelWrapper}`]}>
                        <label>
                            <Typography
                                as="span"
                                variant={getTextTypographyVariant(inputSize)}
                                className={cx([
                                    styles[`${BorderedInputClasses.label}`],
                                    labelClassName,
                                ])}
                            >
                                {inputLabel}
                            </Typography>
                        </label>
                        {required && (
                            <RequiredIcon className={styles[`${BorderedInputClasses.required}`]} />
                        )}
                        {LabelIcon !== null && (
                            <span className={styles[`${BorderedInputClasses.labelIcon}`]}>
                                {LabelIcon}
                            </span>
                        )}
                    </div>
                );
            }

            return <></>;
        };

        const getAdornmentComponent = (Adornment: React.ReactNode, isLeft = false): JSX.Element => {
            const adornmentClass = isLeft
                ? BorderedInputClasses.leftAdornment
                : BorderedInputClasses.rightAdornment;

            if (Adornment) {
                return (
                    <Typography
                        as="span"
                        variant={getInputSizeTypographyVariant(inputSize)}
                        className={cx(
                            styles[`${adornmentClass}`],
                            getAdornmentClass(adornmentClass, inputStatus),
                        )}
                    >
                        {Adornment}
                    </Typography>
                );
            }

            return <></>;
        };

        const getHelperTextMessage = (): JSX.Element => {
            if (
                (typeof success === 'string' && success !== '') ||
                (typeof error === 'string' && error !== '')
            ) {
                return (
                    <div
                        className={cx(
                            styles[`${BorderedInputClasses.helperTextWrapper}`],
                            helperTextClasses,
                        )}
                    >
                        <div className={styles[`${BorderedInputClasses.helperTextIcon}`]}>
                            {Icon !== null && Icon}
                        </div>
                        <Typography
                            as="span"
                            variant={getTextTypographyVariant(inputSize)}
                            className={getHelperTextVariantClass(success, error)}
                        >
                            {success || error}
                        </Typography>
                    </div>
                );
            }

            return <></>;
        };

        const getHintMessage = (): JSX.Element => {
            if (hintMsg !== '') {
                return (
                    <div
                        className={cx(
                            styles[`${BorderedInputClasses.hintTextWrapper}`],
                            hintTextClasses,
                        )}
                    >
                        {HintMsgIcon !== null && (
                            <span className={styles[`${BorderedInputClasses.hintTextIcon}`]}>
                                {HintMsgIcon}
                            </span>
                        )}
                        <Typography
                            as="span"
                            variant={getTextTypographyVariant(inputSize)}
                            className={styles[`${BorderedInputClasses.hintTextMsgs}`]}
                        >
                            {hintMsg}
                        </Typography>
                    </div>
                );
            }
            return <></>;
        };

        return (
            <div data-testid="bordered-input">
                {getLabelComponent()}
                <div
                    className={cx(
                        styles[`${BorderedInputClasses.wrapper}`],
                        getInputSizeClass(inputSize),
                        getBorderedVariantClass(inputStatus),
                        {
                            [styles[`${BorderedInputClasses.wrapper}--full-width-exclusive`]]:
                                fullWidth,
                        },
                        containerClassName,
                    )}
                >
                    {getAdornmentComponent(LeftInputAdornment, true)}
                    <Typography
                        variant={getInputSizeTypographyVariant(inputSize)}
                        className={styles[`${BorderedInputClasses.inputContainer}`]}
                    >
                        <input
                            ref={ref}
                            value={inputValue}
                            onFocus={handleOnFocus}
                            onBlur={handleOnBlur}
                            onChange={handleChange}
                            placeholder={placeholder}
                            className={cx(
                                styles[`${BorderedInputClasses.wrapper}`],
                                getInputSizeClass(inputSize),
                            )}
                            {...restProps}
                            aria-label="input-box"
                            disabled={disabled}
                        />
                    </Typography>
                    {getAdornmentComponent(RightInputAdornment, false)}
                </div>
                {getHelperTextMessage()}
                {getHintMessage()}
            </div>
        );
    },
);

export default BorderedInput;
