import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const SearchIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={svgWidth}
            height={svgHeight}
            fill="none"
            viewBox="0 0 20 20"
            {...restProps}
        >
            <path
                fill={color}
                fillRule="evenodd"
                d="M12.917 11.667h-.659l-.233-.225a5.417 5.417 0 001.233-4.45c-.391-2.317-2.325-4.167-4.658-4.45A5.42 5.42 0 002.542 8.6c.283 2.333 2.133 4.267 4.45 4.658a5.417 5.417 0 004.45-1.233l.225.233v.659l3.541 3.541a.88.88 0 001.242 0 .88.88 0 000-1.241l-3.533-3.55zm-5 0a3.745 3.745 0 01-3.75-3.75 3.745 3.745 0 013.75-3.75 3.745 3.745 0 013.75 3.75 3.745 3.745 0 01-3.75 3.75z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};

export default React.memo(SearchIcon);
