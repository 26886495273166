import React from 'react';
import cx from 'classnames';
import BorderedInput from '@primitives/BorderedInput';
import Typography from '@primitives/Typography';
import { SliderProps } from './types';
import styles from './Slider.module.scss';
import colors from '../../styles/colors.module.scss';
import checkForOnlyNumberInput from '@utils/CheckForOnlyNumberInput';

const Slider = React.forwardRef<HTMLInputElement, SliderProps>(
    (
        {
            sliderMinValue = 0,
            sliderMaxValue = 100,
            sliderStepSize = 1,
            value = 0,
            showInput = false,
            sliderLabel = '',
            adornment = {},
            handleOutOfRangeInput = true,
            errorText = '',
            onChange,
            ...restProps
        },
        ref,
    ) => {
        const [sliderValue, setSliderValue] = React.useState<number>(value);

        const setSliderBgStyle = (): string => {
            const sliderBarFillPercentage =
                ((sliderValue - sliderMinValue) / (sliderMaxValue - sliderMinValue)) * 100;
            return `linear-gradient(to right, ${colors['--navi-color-blue-base']} 0%, ${colors['--navi-color-blue-base']} ${sliderBarFillPercentage}%, ${colors['--navi-color-blue-bg']} ${sliderBarFillPercentage}%, ${colors['--navi-color-blue-bg']} 100%`;
        };

        const handleRangeInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const inputValue = Number(event.target.value);
            setSliderValue(inputValue);
            onChange && onChange(event);
        };

        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const inputValue = event.target.value;
            if (checkForOnlyNumberInput(inputValue)) {
                setSliderValue(Number(inputValue));
                onChange && onChange(event);
            }
        };

        const handleInputBlur = (): void => {
            if (sliderValue < sliderMinValue || sliderValue > sliderMaxValue) {
                const newValue = sliderValue < sliderMinValue ? sliderMinValue : sliderMaxValue;
                setSliderValue(newValue);
            }
        };

        const renderSliderLabel = (): JSX.Element | false => {
            return (
                sliderLabel !== '' && (
                    <Typography variant="p3" as="span" className={styles['slider-label-text']}>
                        {sliderLabel}
                    </Typography>
                )
            );
        };

        const renderSliderInput = (): JSX.Element | false => {
            const sliderValueLength = sliderValue?.toString()?.length || 1;
            const inputStyles = cx(styles['slider-input'], {
                [styles['slider-input--with-adornment']]: Object.keys(adornment).length !== 0,
            });
            return (
                showInput && (
                    <BorderedInput
                        type="number"
                        value={sliderValue}
                        onChange={handleInputChange}
                        onBlur={handleOutOfRangeInput ? handleInputBlur : undefined}
                        inputSize="full-width"
                        className={inputStyles}
                        style={{
                            width: (sliderValueLength < 10 ? sliderValueLength : 10) + 'ch',
                            textOverflow: 'unset',
                        }}
                        {...adornment}
                        error={errorText !== ''}
                    />
                )
            );
        };

        const renderErrorText = (): JSX.Element | false => {
            return (
                errorText !== '' && (
                    <Typography variant="p5" as="div" className={styles['slider-input-error']}>
                        {errorText}
                    </Typography>
                )
            );
        };

        return (
            <div className={styles['slider-wrapper']}>
                <div className={styles['slider-info']}>
                    {renderSliderLabel()}
                    {renderSliderInput()}
                </div>
                {renderErrorText()}
                <input
                    type="range"
                    value={sliderValue}
                    min={sliderMinValue}
                    max={sliderMaxValue}
                    step={sliderStepSize}
                    className={styles.slider}
                    onInput={handleRangeInputChange}
                    ref={ref}
                    style={{
                        background: setSliderBgStyle(),
                    }}
                    {...restProps}
                />
            </div>
        );
    },
);

export default React.memo(Slider);
