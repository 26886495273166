import React from 'react';
import cx from 'classnames';

import { GridWrapperProps } from '../types';
import styles from './GridContainer.module.scss';
import { useGridRAF } from '../../../hooks/useRAFhook';
import { GridContainerPrefix, gridLayoutValidation } from '../constants';

const GridContainer: React.FC<GridWrapperProps> = ({
    className = '',
    children,
    style,
    ...otherProps
}) => {
    const containerRef = React.useRef<HTMLDivElement>(null);

    useGridRAF(containerRef, gridLayoutValidation.gridContainer);

    return (
        <div
            className={cx(styles[`${GridContainerPrefix}`], className)}
            ref={containerRef}
            style={style}
            {...otherProps}
        >
            {children}
        </div>
    );
};

export default GridContainer;
