import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const ArrowRightIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            width={svgWidth}
            height={svgHeight}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <mask
                id="mask0_4933_58040"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4933_58040)">
                <path
                    d="M8.70005 17.2998C8.51672 17.1165 8.42505 16.8831 8.42505 16.5998C8.42505 16.3165 8.51672 16.0831 8.70005 15.8998L12.6 11.9998L8.70005 8.0998C8.51672 7.91647 8.42505 7.68314 8.42505 7.3998C8.42505 7.11647 8.51672 6.88314 8.70005 6.6998C8.88338 6.51647 9.11671 6.4248 9.40005 6.4248C9.68338 6.4248 9.91672 6.51647 10.1 6.6998L14.7 11.2998C14.8 11.3998 14.871 11.5081 14.913 11.6248C14.9544 11.7415 14.975 11.8665 14.975 11.9998C14.975 12.1331 14.9544 12.2581 14.913 12.3748C14.871 12.4915 14.8 12.5998 14.7 12.6998L10.1 17.2998C9.91672 17.4831 9.68338 17.5748 9.40005 17.5748C9.11671 17.5748 8.88338 17.4831 8.70005 17.2998Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default React.memo(ArrowRightIcon);
