import React from 'react';
import cx from 'classnames';
import Typography from '@primitives/Typography';
import { labelSizeToFontSize } from './constants';
import { StatusProps } from './types';
import styles from './Status.module.scss';

const Status: React.FC<StatusProps> = ({
    variant = 'blue',
    label = '',
    labelSize = 'medium',
    ...restProps
}) => {
    const statusWrapperStyles = cx(styles['status-wrapper'], styles[variant]);

    const renderLabel = (): JSX.Element | false => {
        return (
            label !== '' && (
                <Typography variant={labelSizeToFontSize[labelSize]} color={styles.labelTextColor}>
                    {label}
                </Typography>
            )
        );
    };

    return (
        <div className={statusWrapperStyles} data-testid="status" {...restProps}>
            <span className={styles.dot} data-testid="dot"></span>
            {renderLabel()}
        </div>
    );
};

Status.displayName = 'Status';

export default Status;
