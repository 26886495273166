export const sortAscending = (
    svgWidth: number,
    svgHeight: number,
) => `<svg width=${svgWidth} height=${svgHeight} viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.303867 10.2006L2.82192 12.7173C3.20109 13.0962 3.81359 13.0962 4.19276 12.7173L6.71082 10.2006C7.32332 9.58847 6.88582 8.53906 6.02054 8.53906H0.984423C0.119145 8.53906 -0.308634 9.58847 0.303867 10.2006Z" fill="#969696"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.71176 3.79937L4.1937 1.28273C3.81453 0.903771 3.20203 0.903771 2.82287 1.28273L0.304808 3.79937C-0.307693 4.41153 0.129807 5.46094 0.995086 5.46094L6.0312 5.46094C6.89648 5.46094 7.32426 4.41153 6.71176 3.79937Z" fill="#0276FE"/>
</svg>`;

export const sortDescending = (
    svgWidth: number,
    svgHeight: number,
) => `<svg width=${svgWidth} height=${svgHeight} viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468708 10.2006L2.92532 12.7173C3.29523 13.0962 3.89278 13.0962 4.2627 12.7173L6.71931 10.2006C7.31686 9.58847 6.89004 8.53906 6.04587 8.53906H1.13266C0.288493 8.53906 -0.128846 9.58847 0.468708 10.2006Z" fill="#0276FE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.71879 3.79937L4.26218 1.28273C3.89227 0.903771 3.29472 0.903771 2.9248 1.28273L0.468194 3.79937C-0.129359 4.41153 0.297465 5.46094 1.14163 5.46094L6.05484 5.46094C6.89901 5.46094 7.31635 4.41153 6.71879 3.79937Z" fill="#969696"/>
</svg>`;

export const sortUnSort = (
    svgWidth: number,
    svgHeight: number,
) => `<svg width=${svgWidth} height=${svgHeight} viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.26218 1.28275L6.71879 3.79939C7.31635 4.41155 6.89901 5.46096 6.05484 5.46096H1.14163C0.297465 5.46096 -0.129359 4.41155 0.468194 3.79939L2.9248 1.28275C3.29472 0.903797 3.89227 0.903797 4.26218 1.28275ZM2.92532 12.7173L0.468708 10.2007C-0.128846 9.5885 0.288493 8.53909 1.13266 8.53909H6.04587C6.89004 8.53909 7.31686 9.5885 6.71931 10.2007L4.2627 12.7173C3.89278 13.0963 3.29523 13.0963 2.92532 12.7173Z" fill="#969696"/>
</svg>`;
