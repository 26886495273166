import React from 'react';
import cx from 'classnames';
import Typography from '@primitives/Typography';
import styles from './Tooltip.module.scss';
import { TooltipProps } from './types';

const Tooltip: React.FC<TooltipProps> = ({
    showTooltip = false,
    showOnHover = true,
    text = '',
    position = 'bottom',
    withPointer = true,
    children,
    ...restProps
}) => {
    const tooltipWrapperStyles = cx(styles['tooltip-wrapper'], {
        [styles['tooltip-wrapper-on-hover']]: showOnHover,
        [styles['with-pointer']]: withPointer,
    });
    const tooltipStyles = cx(styles.tooltip, styles[position], {
        [styles['show-tooltip']]: showTooltip,
    });

    return (
        <div className={tooltipWrapperStyles} {...restProps}>
            {children}
            <Typography
                variant="p4"
                color={styles.textColor}
                className={tooltipStyles}
                role="tooltip"
            >
                {text}
            </Typography>
        </div>
    );
};

export default Tooltip;
