import React, { useMemo } from 'react';
import cx from 'classnames';
import Typography from '@primitives/Typography';
import styles from './TextArea.module.scss';
import RequiredIcon from '@icons/RequiredIcon';
import { TextAreaClasses, States } from './constants';
import { TextAreaProps, BorderedVariantType } from './types';
import {
    getBorderedVariantClass,
    getHelperTextVariantClass,
    getSizeTypographyVariant,
    getTextTypographyVariant,
    getStatus,
} from './utils';

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            containerClassName = '',
            hintMsg = '',
            hintMsgIcon = null,
            label = '',
            labelIcon = null,
            icon = null,
            success = false,
            error = false,
            value = '',
            disabled = false,
            required,
            placeholder = '',
            size = 'medium',
            fullWidth = false,
            rows = 3,
            onFocus,
            onBlur,
            onChange,
            ...restProps
        },
        ref,
    ) => {
        const finalInputStatus = getStatus(success, error, disabled);
        const [status, setStatus] = React.useState<BorderedVariantType>(finalInputStatus);
        const [inputValue, setInputValue] = React.useState(value);

        React.useEffect(() => {
            const currentStatus = getStatus(success, error, disabled);
            setStatus(currentStatus);
            setInputValue(value);
        }, [value, success, error, disabled]);

        const handleOnBlur = (event: React.FocusEvent<HTMLTextAreaElement>): void => {
            if (status === States.active) {
                setStatus(finalInputStatus);
            }
            if (onBlur) {
                onBlur(event);
            }
        };

        const handleOnFocus = (event: React.FocusEvent<HTMLTextAreaElement>): void => {
            if (status !== States.disabled) {
                setStatus(States.active);
            }
            if (onFocus) {
                onFocus(event);
            }
        };

        const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
            const { value } = event.target;
            setInputValue(value);
            if (onChange) {
                onChange(event);
            }
        };

        const getLabelComponent = useMemo(() => {
            if (label !== '') {
                return (
                    <div className={styles[`${TextAreaClasses.labelWrapper}`]}>
                        <label>
                            <Typography
                                as="span"
                                variant={getTextTypographyVariant(size)}
                                className={styles[`${TextAreaClasses.label}`]}
                            >
                                {label}
                            </Typography>
                        </label>
                        {required && (
                            <RequiredIcon
                                data-testid="textarea-required-icon"
                                className={styles[`${TextAreaClasses.required}`]}
                            />
                        )}
                        {labelIcon !== null && (
                            <span className={styles[`${TextAreaClasses.labelIcon}`]}>
                                {labelIcon}
                            </span>
                        )}
                    </div>
                );
            }

            return null;
        }, [label, required, labelIcon, size]);

        const getHelperTextMessage = useMemo(() => {
            if (
                (typeof success === 'string' && success !== '') ||
                (typeof error === 'string' && error !== '')
            ) {
                return (
                    <div className={styles[`${TextAreaClasses.helperTextWrapper}`]}>
                        <div className={styles[`${TextAreaClasses.helperTextIcon}`]}>
                            {icon !== null && icon}
                        </div>
                        <Typography
                            as="span"
                            variant={getTextTypographyVariant(size)}
                            className={getHelperTextVariantClass(success, error)}
                        >
                            {success || error}
                        </Typography>
                    </div>
                );
            }

            return null;
        }, [success, error, icon, size]);

        const getHintMessage = useMemo(() => {
            if (hintMsg !== '') {
                return (
                    <div className={styles[`${TextAreaClasses.hintTextWrapper}`]}>
                        {hintMsgIcon !== null && (
                            <span className={styles[`${TextAreaClasses.hintTextIcon}`]}>
                                {hintMsgIcon}
                            </span>
                        )}
                        <Typography
                            as="span"
                            variant={getTextTypographyVariant(size)}
                            className={styles[`${TextAreaClasses.hintTextMsgs}`]}
                        >
                            {hintMsg}
                        </Typography>
                    </div>
                );
            }
            return null;
        }, [hintMsg, hintMsgIcon, size]);

        return (
            <div>
                {getLabelComponent}
                <div
                    className={cx(
                        styles[`${TextAreaClasses.wrapper}`],
                        getBorderedVariantClass(status),
                        containerClassName,
                        { [styles[`${TextAreaClasses.fullWidth}`]]: fullWidth },
                    )}
                >
                    <Typography
                        variant={getSizeTypographyVariant(size)}
                        className={styles[`${TextAreaClasses.container}`]}
                    >
                        <textarea
                            ref={ref}
                            value={inputValue}
                            rows={rows}
                            onFocus={handleOnFocus}
                            onBlur={handleOnBlur}
                            onChange={handleChange}
                            placeholder={placeholder}
                            className={cx(styles[`${TextAreaClasses.wrapper}`])}
                            {...restProps}
                        />
                    </Typography>
                </div>
                {getHelperTextMessage}
                {getHintMessage}
            </div>
        );
    },
);

TextArea.displayName = 'TextArea';
export default TextArea;
