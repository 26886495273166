import React from 'react';
import { IconProps } from '@icons/types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '@icons/utils';
import { DEFAULT_ICON_SIZE, REQ_ICON_DEFAULT_DIMENSION } from '../constants';

// Required icon is only for UI library internal components
const RequiredIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width = REQ_ICON_DEFAULT_DIMENSION,
    height = REQ_ICON_DEFAULT_DIMENSION,
    color = styles.naviRequiredDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg width={svgWidth} height={svgHeight} viewBox="0 0 7 7" fill="none" {...restProps}>
            <path
                d="M3.02273 6.54545L3.10227 4.35795L1.25284 5.53125L0.775568 4.69602L2.72443 3.68182L0.775568 2.66761L1.25284 1.83239L3.10227 3.00568L3.02273 0.818182H3.97727L3.89773 3.00568L5.74716 1.83239L6.22443 2.66761L4.27557 3.68182L6.22443 4.69602L5.74716 5.53125L3.89773 4.35795L3.97727 6.54545H3.02273Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(RequiredIcon);
