import React from 'react';

import { GridWrapperProps } from '../types';
import cx from 'classnames';
import styles from './GridRow.module.scss';
import { GridRowPrefix, gridLayoutValidation } from '../constants';
import { useGridRAF } from '../../../hooks/useRAFhook';

const GridRow: React.FC<GridWrapperProps> = ({
    className = '',
    children,
    style,
    ...otherProps
}) => {
    const containerRowRef = React.useRef<HTMLDivElement>(null);

    useGridRAF(containerRowRef, gridLayoutValidation.gridRow);

    return (
        <div
            className={cx(styles[`${GridRowPrefix}`], className)}
            style={style}
            ref={containerRowRef}
            {...otherProps}
        >
            {children}
        </div>
    );
};

export default GridRow;
