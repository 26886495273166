import { ButtonSize, ButtonVariant } from './types';
import styles from './Button.module.scss';
import { FontType } from '@primitives/Typography/types';
import { buttonSizeToFontType } from './constants';

export const getButtonVariantClass = (variant: ButtonVariant = 'primary') => {
    return styles[`btn--${variant}`];
};

export const getButtonSizeClass = (size: ButtonSize = 'medium') => {
    return styles[`btn--${size}`];
};

export const getTypographyVariant = (buttonSize: ButtonSize = 'medium'): FontType => {
    return buttonSizeToFontType[buttonSize];
};
