import React from 'react';
import cx from 'classnames';
import NaviLogoIcon from '@icons/NaviLogoIcon';
import Typography from '@primitives/Typography';
import FooterWithMenu from './FooterWithMenu';
import DropdownNavItem from './DropdownNavItem';
import { NavbarProps, NavItemType, SimpleNavItemType } from './types';
import { getNavItemLabelColor } from './utils';
import styles from './Navbar.module.scss';
import PopupNavItem from './PopupNavItem';
import Count from '@primitives/Counter/Counter';

// TODO: handle Semantics properly throughout component.
const Navbar: React.FC<NavbarProps> = ({
    headerContent,
    customPlaceholder,
    navItemList,
    bottomNavItemList,
    footer,
    children,
    handleNavbarStateChange,
}) => {
    const { pathname } = window.location;
    const [isExpanded, setIsExpanded] = React.useState(false);
    const activeDropdownNavItemIndex = React.useRef(-1);
    const [activePopupNavItemIndex, setActivePopupNavItemIndex] = React.useState(-1);

    React.useEffect(() => {
        handleNavbarStateChange && handleNavbarStateChange(isExpanded);
    }, [isExpanded]);

    const navBarStyles = cx(styles.navbar, {
        [styles['navbar--expanded']]: isExpanded,
    });

    const setNavItemStyles = (isActive: boolean): string => {
        return cx(styles['nav-item'], {
            [styles['nav-item--active']]: isActive,
        });
    };

    const renderNavItemWhenCollapsed = (navItem: NavItemType, index: number): JSX.Element => {
        const { label, Icon, itemType } = navItem;

        let navItemStyles = cx(styles['nav-item']);
        let isActive = false;
        let count = null;

        if (itemType === 'dropdownNavItem') {
            const { options } = navItem;
            for (let i = 0; i < options.length; i++) {
                if (options[i].route === pathname) {
                    isActive = true;
                    activeDropdownNavItemIndex.current = index;
                    break;
                }
            }
            navItemStyles = setNavItemStyles(isActive);
        } else if (itemType === 'popupNavItem') {
            count = navItem.count;
            navItemStyles = setNavItemStyles(isActive);
        } else {
            count = navItem.count;
            isActive = pathname === navItem.route;
            navItemStyles = setNavItemStyles(isActive);
        }

        return (
            <li key={label + index} className={navItemStyles}>
                <Icon size="md" color={getNavItemLabelColor(isActive)} />
                {!!count && (
                    <Count
                        counterClassName={styles['counter']}
                        overflowCount={9}
                        value={count}
                        color="red"
                        size="sm"
                    />
                )}
            </li>
        );
    };

    const renderNavItemWhenExpanded = (navItem: NavItemType, index: number): JSX.Element => {
        const { label, Icon, itemType } = navItem;
        if (itemType === 'dropdownNavItem') {
            const dropdownExpanded = activeDropdownNavItemIndex.current === index;
            return <DropdownNavItem {...{ navItem, index, dropdownExpanded }} />;
        }

        if (itemType === 'popupNavItem') {
            return (
                <PopupNavItem
                    open={activePopupNavItemIndex === index}
                    handleToggle={() =>
                        setActivePopupNavItemIndex(activePopupNavItemIndex === index ? -1 : index)
                    }
                    handleCollapseNavbar={() => setIsExpanded(false)}
                    navItem={navItem}
                    index={index}
                />
            );
        }

        const { route, handleNavigation, count } = navItem;
        const contentColor = getNavItemLabelColor(pathname === route);

        return (
            <li
                key={label + index}
                className={setNavItemStyles(pathname === route)}
                onClick={handleNavigation}
            >
                <div className={styles['nav-item-content']}>
                    <Icon size="md" color={contentColor} />
                    <Typography
                        variant="p3"
                        color={contentColor}
                        className={styles['nav-item__label']}
                    >
                        {label}
                    </Typography>
                    {!!count && <Count overflowCount={9} value={count} color="red" size="sm" />}
                </div>
            </li>
        );
    };

    const renderHorizontalDivider = (): JSX.Element => {
        return <div className={styles['horizontal-divider']}></div>;
    };

    return (
        <div className={styles['page-container']}>
            <aside className={styles['navbar-wrapper']}>
                <nav
                    className={navBarStyles}
                    onMouseEnter={() => setIsExpanded(true)}
                    onMouseLeave={() => setIsExpanded(false)}
                >
                    <section className={styles.content}>
                        <header className={styles.header}>
                            {headerContent || <NaviLogoIcon size="md" />}
                            {customPlaceholder}
                        </header>
                        {renderHorizontalDivider()}
                        <ul className={styles['nav-items-list']}>
                            {navItemList.map((navItem, index) => {
                                return !isExpanded
                                    ? renderNavItemWhenCollapsed(navItem, index)
                                    : renderNavItemWhenExpanded(navItem, index);
                            })}
                        </ul>
                    </section>
                    <section className={styles.content}>
                        {bottomNavItemList && (
                            <ul className={styles['nav-items-list']}>
                                {bottomNavItemList.map((navItem, index) => {
                                    const newIndex = index + navItemList.length;
                                    return !isExpanded
                                        ? renderNavItemWhenCollapsed(navItem, newIndex)
                                        : renderNavItemWhenExpanded(navItem, newIndex);
                                })}
                            </ul>
                        )}
                        {renderHorizontalDivider()}
                        <FooterWithMenu {...footer} isExpanded={isExpanded} />
                    </section>
                </nav>
            </aside>
            <main className={styles['page-content-wrapper']}>{children}</main>
        </div>
    );
};

Navbar.displayName = 'Navbar';

export default React.memo(Navbar);
