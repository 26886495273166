import React, { ChangeEvent, useMemo, KeyboardEvent } from 'react';
import SearchIcon from '@icons/SearchIcon';
import BorderedInput from '@primitives/BorderedInput';
import debounce from '@utils/Debounce';
import { SearchBarInputProps } from './types';
import { getSearchIconSize } from './utils';

const SearchBarInput = React.forwardRef<HTMLInputElement, SearchBarInputProps>(
    (
        {
            debounceDelay,
            onSearchChange,
            placeholder = 'Search',
            inputSize = 'medium',
            ...restProps
        },
        ref,
    ) => {
        const debounceChangeHandler = useMemo(
            () =>
                debounce((textValue: string) => {
                    onSearchChange(textValue);
                }, debounceDelay),
            [],
        );

        const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
            const textValue = e.target.value;
            if (debounceDelay) {
                debounceChangeHandler(textValue);
            } else {
                onSearchChange(textValue);
            }
        };

        const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
            const pressedKey = e.key;
            const textValue = e.target.value;
            if (pressedKey === 'Enter') {
                onSearchChange(textValue);
            }
        };

        return (
            <BorderedInput
                ref={ref}
                inputSize={inputSize}
                placeholder={placeholder}
                LeftInputAdornment={<SearchIcon size={getSearchIconSize(inputSize)} />}
                onChange={inputChangeHandler}
                onKeyDown={handleOnKeyDown}
                {...restProps}
            />
        );
    },
);

export default SearchBarInput;
