import React from 'react';
import cx from 'classnames';
import Avatar from '@primitives/Avatar';
import Switch from '@primitives/Switch';
import Typography from '@primitives/Typography';
import useOutsideClick from '@hooks/useOutsideClick';
import { FooterWithMenuProps } from './types';
import { getNavItemLabelColor } from '../utils';
import styles from './FooterWithMenu.module.scss';

interface MenuProps {
    options: {
        label: string;
        handleClick: () => void;
    }[];
    closeMenu: () => void;
}

const Menu: React.FC<MenuProps> = (props) => {
    const { options, closeMenu } = props;

    return (
        <ul className={styles['menu-wrapper']} role="menu">
            {options.map((option, index) => {
                const { label, handleClick } = option;
                return (
                    <li
                        className={styles['menu-item']}
                        onClick={() => {
                            handleClick();
                            closeMenu();
                        }}
                        role="menuitem"
                        key={index}
                    >
                        <Typography variant="p3" color={getNavItemLabelColor(true)}>
                            {label}
                        </Typography>
                    </li>
                );
            })}
        </ul>
    );
};

const FooterWithMenu: React.FC<FooterWithMenuProps> = (props) => {
    const {
        avatar,
        footerText,
        options,
        isExpanded,
        role = '',
        showStatus = false,
        statusOnline = false,
        onStatusChange,
    } = props;
    const [showMenu, setShowMenu] = React.useState(false);
    const footerRef = React.useRef(null);

    const footerStyles = cx(styles.footer, {
        [styles['footer__menu-open']]: showMenu,
    });

    React.useEffect(() => {
        if (!isExpanded) {
            setShowMenu(false);
        }
    }, [isExpanded]);

    const renderFooterContent = (): JSX.Element | false => {
        return (
            isExpanded && (
                <div className={styles['footer__content']}>
                    <div>
                        <Typography variant="p3" color={getNavItemLabelColor(true)}>
                            {footerText}
                        </Typography>
                        {role !== '' && (
                            <Typography variant="p5" color={getNavItemLabelColor(false)}>
                                {role}
                            </Typography>
                        )}
                    </div>
                    {showStatus && (
                        <Switch
                            checked={statusOnline}
                            variant="secondary"
                            onChange={onStatusChange}
                        />
                    )}
                </div>
            )
        );
    };

    useOutsideClick(footerRef, () => setShowMenu(false));

    return (
        <footer className={styles['footer-wrapper']} ref={footerRef}>
            {isExpanded && showMenu && (
                <Menu options={options} closeMenu={() => setShowMenu(false)} />
            )}
            <section
                className={footerStyles}
                onClick={() => setShowMenu((prevState) => !prevState)}
                data-testid="footer"
            >
                {avatar || (
                    <Avatar
                        isImage
                        sizeVariant={role !== '' && isExpanded ? 'md' : 'sm'}
                        status={showStatus ? statusOnline : 'noStatus'}
                    />
                )}
                {renderFooterContent()}
            </section>
        </footer>
    );
};

export default FooterWithMenu;
