import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SingleOTPInput from './SingleOTPInput';
import { OTPInputProps } from './types';
import styles from './OTPInput.module.scss';

const OTPInput: React.FC<OTPInputProps> = ({
    numOfInputs = 4,
    isInputNum = true,
    isInputSecure = false,
    inputSeparator = '',
    disabled = false,
    onChange,
    onBlur,
    onKeyDown,
    value,
    autoFocus = true,
    size = 'large',
    error = false,
}) => {
    const getInitialOtpValue = useMemo(() => {
        const otpArray = value?.split('') || [];
        if (otpArray.length > numOfInputs) return otpArray.slice(0, numOfInputs);
        return otpArray.concat(Array(numOfInputs - otpArray.length).fill(''));
    }, [value, numOfInputs]);

    const [otpValue, setOtpValue] = useState<Array<string>>(getInitialOtpValue);
    const [activeInputIdx, setActiveInputIdx] = useState<number>(!autoFocus ? -1 : 0);

    useEffect(() => {
        setOtpValue(getInitialOtpValue);
    }, [value]);

    const inputType = useMemo(() => {
        if (isInputNum) return 'number';
        return 'text';
    }, [isInputNum]);

    const focusInput = useCallback(
        (inputIndex: number) => {
            const selectedIndex = Math.max(Math.min(numOfInputs - 1, inputIndex), 0);
            setActiveInputIdx(selectedIndex);
        },
        [numOfInputs],
    );

    const handleOnFocus = useCallback(
        (index: number) => () => {
            focusInput(index);
        },
        [focusInput],
    );

    const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        setActiveInputIdx(-1);
        if (typeof onBlur === 'function') onBlur(e);
    }, []);

    const handleOtpChange = useCallback(
        (otpValue: string[]) => {
            const newOtpValue = otpValue.join('');
            if (typeof onChange === 'function') onChange(newOtpValue);
        },
        [onChange],
    );

    const focusNextInput = useCallback(() => {
        focusInput(activeInputIdx + 1);
    }, [activeInputIdx, focusInput]);

    const focusPrevInput = useCallback(() => {
        focusInput(activeInputIdx - 1);
    }, [activeInputIdx, focusInput]);

    const changeCodeAtFocus = useCallback(
        (str: string) => {
            const updatedOTPValue = [...otpValue];
            const len = str.length;
            updatedOTPValue[activeInputIdx] = str[len - 1] || '';
            setOtpValue(updatedOTPValue);
            handleOtpChange(updatedOTPValue);
        },
        [activeInputIdx, handleOtpChange, otpValue],
    );

    const handleOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = e.currentTarget.value;
            if (!val) return;
            changeCodeAtFocus(val);
            focusNextInput();
        },
        [focusInput, changeCodeAtFocus],
    );

    const handleOnKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const pressedKey = e.key;

            switch (pressedKey) {
                case 'Backspace':
                case 'Delete': {
                    e.preventDefault();
                    changeCodeAtFocus('');
                    focusPrevInput();
                    break;
                }
                case 'ArrowLeft': {
                    e.preventDefault();
                    focusPrevInput();
                    break;
                }
                case 'ArrowRight': {
                    e.preventDefault();
                    focusNextInput();
                    break;
                }
                default: {
                    if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
                        e.preventDefault();
                    }

                    break;
                }
            }

            if (onKeyDown) {
                onKeyDown(e);
            }
        },
        [activeInputIdx, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValue],
    );

    const handleOnPaste = useCallback(
        (e: React.ClipboardEvent<HTMLInputElement>) => {
            e.preventDefault();
            const pastedData = e.clipboardData
                .getData('text/plain')
                .trim()
                .slice(0, numOfInputs - activeInputIdx)
                .split('');
            if (pastedData) {
                let nextFocusIndex = 0;
                const updatedOTPValues = [...otpValue];

                for (let index = 0; index < updatedOTPValues.length; index++) {
                    if (index >= activeInputIdx) {
                        const changedValue = pastedData.shift() || updatedOTPValues[index];

                        if (changedValue) {
                            updatedOTPValues[index] = changedValue;
                            nextFocusIndex = index;
                        } else {
                            break;
                        }
                    }
                }
                handleOtpChange(updatedOTPValues);
                setOtpValue(updatedOTPValues);
                setActiveInputIdx(Math.min(nextFocusIndex + 1, numOfInputs - 1));
            }
        },
        [activeInputIdx, numOfInputs, otpValue],
    );

    const areInputsActive = useMemo(() => {
        return activeInputIdx >= 0 && activeInputIdx < numOfInputs && !disabled;
    }, [activeInputIdx, numOfInputs, disabled]);

    return (
        <div className={styles['otp-container']}>
            {Array(numOfInputs)
                .fill('')
                .map((_, index) => (
                    <SingleOTPInput
                        key={index}
                        inputType={inputType}
                        disabled={disabled}
                        inputSeparator={inputSeparator}
                        isLastInput={index === numOfInputs - 1}
                        onFocus={handleOnFocus(index)}
                        onBlur={handleBlur}
                        onKeyDown={handleOnKeyDown}
                        focus={index === activeInputIdx}
                        onPaste={handleOnPaste}
                        onChange={handleOnChange}
                        value={otpValue?.[index]}
                        size={size}
                        active={areInputsActive}
                        error={error}
                        isInputSecure={isInputSecure}
                    />
                ))}
        </div>
    );
};

export default React.memo(OTPInput);
