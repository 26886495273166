import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const ArrowUpSolidIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            width={svgWidth}
            height={svgHeight}
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.58711 4.01454L5.98011 0.407545C5.43697 -0.135593 4.5596 -0.135593 4.01646 0.407545L0.40946 4.01454C-0.467917 4.89192 0.158781 6.396 1.39825 6.396L8.61224 6.396C9.85171 6.396 10.4645 4.89192 9.58711 4.01454Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(ArrowUpSolidIcon);
