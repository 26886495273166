import { RefObject, useEffect } from 'react';

const useOutsideClick = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    callback: () => void,
) => {
    const handleClick = (event: Event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    });
};

export default useOutsideClick;
