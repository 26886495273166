import { CounterColorVariants, CounterSizeVariants } from '@primitives/Counter/types';
import { DEFAULT_COUNTER_COLOR, DEFAULT_COUNTER_SIZE } from '@primitives/Counter/constants';

import classes from './Counter.module.scss';

export const getCounterSizeVariantClass = (size?: CounterSizeVariants) => {
    if (!size) size = DEFAULT_COUNTER_SIZE;
    return classes[size];
};

export const getCounterColorVariantClass = (color?: CounterColorVariants) => {
    if (!color) color = DEFAULT_COUNTER_COLOR;
    return classes[color];
};
