import { HelperTextVariantType, BorderedVariantType, TextAreaSizeVariant } from './types';
import {
    States,
    HelperTextVariants,
    textToFontType,
    sizeToFontType,
    TextAreaClasses,
} from './constants';
import { FontType } from '@primitives/Typography/types';
import styles from './TextArea.module.scss';

export const getStatus = (
    success: HelperTextVariantType,
    error: HelperTextVariantType,
    disabled: boolean,
): BorderedVariantType => {
    if (disabled) {
        return States.disabled;
    }

    if (success) {
        return HelperTextVariants.success;
    }

    if (error) {
        return HelperTextVariants.error;
    }

    return States.default;
};

export const getTextTypographyVariant = (size: TextAreaSizeVariant = 'small'): FontType => {
    return textToFontType[size];
};

export const getSizeTypographyVariant = (size: TextAreaSizeVariant = 'small'): FontType => {
    return sizeToFontType[size];
};

export const getHelperTextVariantClass = (
    success: HelperTextVariantType,
    error: HelperTextVariantType,
) => {
    if (success) {
        return styles[`${TextAreaClasses.helperTextWrapper}--${HelperTextVariants.success}`];
    }
    if (error) {
        return styles[`${TextAreaClasses.helperTextWrapper}--${HelperTextVariants.error}`];
    }
    return '';
};

export const getBorderedVariantClass = (variant: BorderedVariantType = 'default') => {
    return styles[`${TextAreaClasses.wrapper}--${variant}`];
};
