import React, { useEffect } from 'react';
import cx from 'classnames';

import { CloseButton } from './CloseButton';
import { ToastProps } from '../types';
import { Default, isFn } from '../utils';
import { useToast } from '../hooks/useToast';
import styles from '../scss/main.module.scss';
export const Toast: React.FC<ToastProps> = (props) => {
    const { isRunning, preventExitTransition, toastRef, eventHandlers } = useToast(props);
    const {
        isColored,
        closeButton,
        children,
        autoClose,
        onClick,
        type,
        closeToast,
        transition: Transition,
        position,
        className,
        style,
        bodyClassName,
        bodyStyle,
        updateId,
        role,
        rtl,
        toastId,
        deleteToast,
        isIn,
        isLoading,
        iconOut,
    } = props;
    const defaultClassName = cx(styles['navi-toast__toast'], styles[`navi-toast__toast--${type}`], {
        [styles[`navi-toast__toast--rtl`]]: rtl,
        [styles[`navi-toast__toast--colored`]]: isColored,
    });
    const cssClasses = isFn(className)
        ? className({
              rtl,
              position,
              type,
              defaultClassName,
          })
        : cx(defaultClassName, className);

    const closeButtonProps = { closeToast, type, isColored };
    let Close: React.ReactNode = null;

    if (closeButton === false) {
        // hide
    } else if (isFn(closeButton)) {
        Close = closeButton(closeButtonProps);
    } else if (React.isValidElement(closeButton)) {
        Close = React.cloneElement(closeButton, closeButtonProps);
    } else {
        Close = CloseButton(closeButtonProps);
    }

    useEffect(() => {
        if (typeof autoClose === 'number') {
            const timer = setTimeout(closeToast, autoClose);
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        <Transition
            isIn={isIn}
            done={deleteToast}
            position={position}
            preventExitTransition={preventExitTransition}
            nodeRef={toastRef}
        >
            <div
                id={toastId as string}
                onClick={onClick}
                className={cssClasses}
                {...eventHandlers}
                style={style}
                ref={toastRef}
            >
                <div
                    {...(isIn && { role: role })}
                    className={
                        isFn(bodyClassName)
                            ? bodyClassName({ type })
                            : cx(styles['navi-toast__toast-body'], bodyClassName)
                    }
                    style={bodyStyle}
                >
                    {iconOut != null && (
                        <div
                            className={cx(styles['navi-toast__toast-icon'], {
                                [styles['navi-toast--animate-icon']]: !isLoading,
                            })}
                        >
                            {iconOut}
                        </div>
                    )}
                    <div>{children as any}</div>
                </div>
                {Close}
            </div>
        </Transition>
    );
};

export default Toast;
