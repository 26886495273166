import { OtpSize } from './types';
import styles from './OTPInput.module.scss';

export const getOtpSizeClass = (size: OtpSize) => {
    return styles[`single-input--${size}`];
};

export const getOtpStateClass = (state: string) => {
    if (state) return styles[`single-input--${state}`];
    return '';
};
