import { sortOrderType } from './types';

export const AgTableHeaderClasses = {
    base: 'header-wrapper',
    sortWrapper: 'header-wrapper__sort',
};

export const SortDirections: { [key: string]: sortOrderType } = {
    ASCENDING: 'asc',
    DESCENDING: 'desc',
    NO_SORT: '',
};

export const SortDirectionMap: { [key: string]: sortOrderType } = {
    [SortDirections.ASCENDING as string]: SortDirections.DESCENDING,
    [SortDirections.DESCENDING as string]: SortDirections.NO_SORT,
    [SortDirections.NO_SORT as string]: SortDirections.ASCENDING,
};
