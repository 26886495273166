import React, { useEffect, useMemo, useRef } from 'react';
import { SingleOTPInputProps } from './types';
import styles from './OTPInput.module.scss';
import usePrevious from '@hooks/usePreviousHook';
import cx from 'classnames';
import { getOtpSizeClass, getOtpStateClass } from './utils';

const SingleOTPInput: React.FC<SingleOTPInputProps> = (props) => {
    const {
        inputSeparator,
        inputType,
        isInputSecure,
        isLastInput,
        focus,
        active,
        size,
        error,
        ...rest
    } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    // usePrevious hook works like componentDidUpdate to get previous value
    const prevFocus = usePrevious(!!focus);

    useEffect(() => {
        if (inputRef.current && focus && focus !== prevFocus) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [focus, prevFocus]);

    const inputStyle = useMemo(() => {
        let state = '';
        if (error) state = 'error';
        else if (active) state = 'active';
        return cx(styles['single-input'], getOtpSizeClass(size), getOtpStateClass(state), {
            [styles['single-input--secured']]: isInputSecure,
        });
    }, [size, error, active, isInputSecure]);

    return (
        <div className={styles['single-input-container']}>
            <input className={inputStyle} ref={inputRef} type={inputType} {...rest} />
            {!isLastInput && inputSeparator}
        </div>
    );
};

export default React.memo(SingleOTPInput);
