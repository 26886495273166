export const SIZE_MAP = {
    xs: { width: 16, height: 16 },
    sm: { width: 20, height: 20 },
    md: { width: 24, height: 24 },
    lg: { width: 32, height: 32 },
    xl: { width: 48, height: 48 },
};

export const DEFAULT_ICON_SIZE = 'xs';

export const REQ_ICON_DEFAULT_DIMENSION = 7;
