import styles from './BorderLessInput.module.scss';
import {
    inputStates,
    BorderLessInputClasses,
    HelperTextVariants,
    BorderLessInputActionTypes,
} from './constants';
import {
    BorderLessVariantType,
    HelperTextVariantType,
    HelperTextClassType,
    BorderLessInputStateType,
    BorderLessInputActions,
} from './types';

export const getBorderLessVariantClass = (variant: BorderLessVariantType = 'default') => {
    return styles[`${BorderLessInputClasses.wrapper}--${variant}`];
};

export const getInputStatus = (
    success: HelperTextVariantType,
    error: HelperTextVariantType,
    disabled: boolean | undefined,
): BorderLessVariantType => {
    if (disabled) {
        return inputStates.disabled;
    }

    if (success) {
        return HelperTextVariants.success;
    }

    if (error) {
        return HelperTextVariants.error;
    }

    return inputStates.default;
};

export const getHelperTextVariantClass = (
    success: HelperTextVariantType,
    error: HelperTextVariantType,
) => {
    if (success) {
        return styles[`${BorderLessInputClasses.helperTextMsgs}--${HelperTextVariants.success}`];
    }
    if (error) {
        return styles[`${BorderLessInputClasses.helperTextMsgs}--${HelperTextVariants.error}`];
    }
    return '';
};

export const getFullWidthVariantClass = (fullWidth: boolean) => {
    return (fullWidth && styles[`${BorderLessInputClasses.inputContainer}`]) || '';
};

//TODO: This util will utilized once icon is ready.
export const getHelperTextIconColor = (variant: HelperTextClassType | undefined) => {
    if (variant === 'error') {
        return styles.naviErrorIcon;
    }
    if (variant === 'success') {
        return styles.naviSuccessIcon;
    }
};

export const BorderLessInputReducer = (
    state: BorderLessInputStateType,
    action: BorderLessInputActions,
): BorderLessInputStateType => {
    switch (action?.type) {
        case BorderLessInputActionTypes.SET_LABEL_VISIBILITY: {
            return {
                ...state,
                showLabel: action.payload,
            };
        }
        case BorderLessInputActionTypes.SET_INPUT_LABEL: {
            return {
                ...state,
                inputPlaceholder: action.payload,
            };
        }
        case BorderLessInputActionTypes.SET_INPUT_STATUS: {
            return {
                ...state,
                inputStatus: action.payload,
            };
        }
        case BorderLessInputActionTypes.SET_INPUT_VALUE: {
            return {
                ...state,
                ...action?.payload,
            };
        }
        case BorderLessInputActionTypes.SET_CLEAR_ICON_VISIBILITY: {
            return {
                ...state,
                showClearIcon: action.payload,
            };
        }
        case BorderLessInputActionTypes.HANDLE_ON_BLUR: {
            return {
                ...state,
                ...action?.payload,
            };
        }
        case BorderLessInputActionTypes.HANDLE_ON_FOCUS: {
            return {
                ...state,
                ...action?.payload,
            };
        }
        case BorderLessInputActionTypes.HANDLE_VALUE_CHANGE: {
            return {
                ...state,
                ...action?.payload,
            };
        }
        default:
            return state;
    }
};

export const getInputAdornmentClass = (variant: BorderLessVariantType = 'default') => {
    return styles[`${BorderLessInputClasses.inputAdornmentClass}--${variant}`];
};
