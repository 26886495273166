import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Typography from '@primitives/Typography';
import CloseIcon from '@icons/CloseIcon';
import { DomElementIds } from '@constants';
import { DrawerProps } from './types';
import ReactPortal from '../ReactPortal';
import styles from './Drawer.module.scss';

const Drawer: React.FC<DrawerProps> = ({
    open = false,
    onClose,
    children,
    usePadding = true,
    headerText = '',
    showHeaderDivider = false,
    footer = false,
    className,
    headerContainerClasses,
    footerContainerClasses,
    ...restProps
}) => {
    const { drawerPortalId } = DomElementIds;
    const [headerClassList, setHeaderClassList] = useState<string>(styles['drawer__header']);

    const drawerContainerStyles = cx(styles['drawer-container'], {
        [styles['is-open']]: open,
    });

    const drawerContentStyles = cx(styles['drawer__content'], {
        [styles['drawer__content--with-padding']]: usePadding,
        [styles['drawer__content--with-header']]: !showHeaderDivider,
    });

    useEffect(() => {
        setHeaderClassList(
            cx(
                styles['drawer__header'],
                {
                    [styles['drawer__header--with-divider']]: showHeaderDivider,
                },
                headerContainerClasses,
            ),
        );
    }, [showHeaderDivider]);

    const handleClose = (): void => {
        document.body.style.overflow = 'unset';
        onClose();
    };

    if (open) document.body.style.overflow = 'hidden';

    const handleDrawerScroll = (event: any): void => {
        !showHeaderDivider &&
            setHeaderClassList(
                cx(
                    styles['drawer__header'],
                    {
                        [styles['drawer__header--with-shadow']]: event.target.scrollTop !== 0,
                    },
                    headerContainerClasses,
                ),
            );
    };

    const renderHeaderText = (): JSX.Element | false => {
        return headerText.length > 0 && <Typography variant="h3">{headerText}</Typography>;
    };

    const renderFooter = (): JSX.Element | false => {
        return (
            footer && (
                <div className={cx(styles['drawer__footer'], footerContainerClasses)}>{footer}</div>
            )
        );
    };

    return (
        <ReactPortal wrapperId={drawerPortalId}>
            <div className={drawerContainerStyles} onClick={handleClose}>
                <div
                    className={cx(styles['drawer'], className)}
                    onClick={(e) => e.stopPropagation()}
                    onScroll={handleDrawerScroll}
                    {...restProps}
                >
                    <div className={headerClassList}>
                        <CloseIcon
                            size="sm"
                            onClick={handleClose}
                            className={styles['close-icon']}
                        />
                        {renderHeaderText()}
                    </div>
                    <div className={drawerContentStyles}>{children}</div>
                    {renderFooter()}
                </div>
            </div>
        </ReactPortal>
    );
};

export default Drawer;
