import ArrowDownSolidIcon from '@icons/ArrowDownSolidIcon';
import ArrowUpSolidIcon from '@icons/ArrowUpSolidIcon';
import React, { useState } from 'react';
import styles from './AgTable.module.scss';
import { AgTableHeaderClasses, SortDirections, SortDirectionMap } from './constants';
import { ColDefsType, IAgTableSortHeader, sortOrderType } from './types';

const AgTableSortHeader = ({ column, displayName, onSortChanged }: IAgTableSortHeader) => {
    const colDef: ColDefsType = column.getColDef();
    const [sortDir, setSortDir] = useState<sortOrderType | undefined>(colDef.serverSort);

    const handleSortClicked = () => {
        const newSortDir = SortDirectionMap[sortDir || SortDirections.NO_SORT];
        const sortModel: ColDefsType = {
            ...colDef,
            serverSort: newSortDir,
        };
        setSortDir(newSortDir);
        onSortChanged(sortModel);
    };

    const getIconColor = (sortDir: sortOrderType | undefined, iconDir: 'UP' | 'DOWN') => {
        if (sortDir === SortDirections.ASCENDING && iconDir === 'UP') {
            return styles.naviAgTableSortIconActive;
        }
        if (sortDir === SortDirections.ASCENDING && iconDir === 'DOWN') {
            return styles.naviAgTableSortIconDefault;
        }
        if (sortDir === SortDirections.DESCENDING && iconDir === 'UP') {
            return styles.naviAgTableSortIconDefault;
        }
        if (sortDir === SortDirections.DESCENDING && iconDir === 'DOWN') {
            return styles.naviAgTableSortIconActive;
        }
    };

    const renderSortIcons = (): JSX.Element => {
        const { serverSortable } = colDef;
        if (!serverSortable) {
            return <></>;
        }
        return (
            <div className={styles[AgTableHeaderClasses.sortWrapper]} onClick={handleSortClicked}>
                <ArrowUpSolidIcon width={7} color={getIconColor(sortDir, 'UP')} />
                <ArrowDownSolidIcon width={7} color={getIconColor(sortDir, 'DOWN')} />
            </div>
        );
    };

    return (
        <div className={styles[AgTableHeaderClasses.base]}>
            {displayName}
            {renderSortIcons()}
        </div>
    );
};

export default AgTableSortHeader;
