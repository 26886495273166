import React, { ChangeEventHandler, Children, createElement } from 'react';
import Radio from '@primitives/Radio';
import { ChildrenType, RadioChipOtherProps, RadioGroupProps, RadioOtherProps } from './types';
import styles from './RadioGroup.module.scss';
import cx from 'classnames';
import { RadioProps } from '@primitives/Radio/types';
import { ChipProps } from '@primitives/Chip/types';
import Chip from '@primitives/Chip';
import { RADIO_OPTION_TYPES } from './const';
import Typography from '@primitives/Typography';
import RequiredIcon from '@icons/RequiredIcon';

const { DEFAULT, CHIP } = RADIO_OPTION_TYPES;

const RadioGroup: React.FC<RadioGroupProps> = ({
    options,
    value,
    defaultValue,
    disabled = false,
    direction = 'row',
    name,
    children,
    onChange,
    className,
    optionType = DEFAULT,
    label = '',
    required = false,
    inputLabelIcon: LabelIcon = null,
    labelClassName = '',
    error = '',
    errorIcon: ErrorIcon = null,
}) => {
    const getChildren = (children: ChildrenType) => {
        if (options && name && optionType === DEFAULT) {
            return options.map((option) => {
                const forwardProps: RadioProps = {
                    key: option.id,
                    name,
                    id: option.id,
                    value: option.value,
                    disabled,
                    checked: option.value === value,
                    onChange: onChange as ChangeEventHandler<HTMLInputElement>,
                    label: option.label,
                    ...(option.otherProps as RadioOtherProps),
                };

                if (value === undefined || value === null) delete forwardProps.checked;

                if (defaultValue) {
                    delete forwardProps.checked;
                    forwardProps.defaultChecked = option.value === defaultValue;
                }

                return <Radio {...forwardProps} />;
            });
        }

        // Type Chip as options
        if (options && name && optionType === CHIP) {
            return options.map((option) => {
                const chipProps: ChipProps = {
                    name,
                    value: option.value,
                    disabled,
                    selected: value ? option.value === value : option.value === defaultValue,
                    onChipClick: onChange as ChangeEventHandler<HTMLButtonElement>,
                    label: option.label,
                    ...(option.otherProps as RadioChipOtherProps),
                };

                return <Chip key={option.id} {...chipProps} />;
            });
        }

        // Type Chip without options .. Chip as children
        else if (optionType === CHIP) {
            return Children?.map(children, (child) => {
                const childChip = child as React.ReactElement;
                const childChipProps = childChip?.props || {};

                const chipLabel = childChipProps.label;
                const chipValue = childChipProps.value;

                const chipForwardProps: ChipProps = {
                    name,
                    value: chipValue,
                    disabled,
                    selected: value ? chipValue === value : chipValue === defaultValue,
                    onChipClick: onChange as ChangeEventHandler<HTMLButtonElement>,
                    label: chipLabel,
                };

                return <Chip {...childChipProps} {...chipForwardProps} />;
            });
        }

        return children;
    };

    const getLabelComponent = (): JSX.Element => {
        if (label) {
            return (
                <div className={styles['radio-group__input-label-wrapper']}>
                    <label>
                        <Typography
                            as="span"
                            variant="p4"
                            className={cx([
                                styles['radio-group__input-label-wrapper--label'],
                                labelClassName,
                            ])}
                        >
                            {label}
                        </Typography>
                    </label>
                    {required && (
                        <RequiredIcon
                            className={styles['radio-group__input-label-wrapper--required']}
                        />
                    )}
                    {LabelIcon !== null && (
                        <span className={styles['radio-group__input-label-wrapper--icon']}>
                            {LabelIcon}
                        </span>
                    )}
                </div>
            );
        }

        return <></>;
    };

    // Will generalize this if there comes case for hint messages or success message.For now this is more than enough
    const getErrorMessage = () => {
        if (error) {
            return (
                <div className={styles['radio-group__error-message']}>
                    <div className={styles['radio-group__error-message--icon']}>{ErrorIcon}</div>
                    <Typography
                        as="span"
                        variant="p4"
                        className={styles['radio-group__error-message--text']}
                    >
                        {error}
                    </Typography>
                </div>
            );
        }

        return null;
    };

    const radioGroupClass = cx(
        styles['radio-group__content'],
        styles[`radio-group__content--${direction}`],
        className,
    );

    return (
        <div data-testid="radio-group" className={styles['radio-group']}>
            {getLabelComponent()}
            <div className={radioGroupClass}>{getChildren(children)}</div>
            {getErrorMessage()}
        </div>
    );
};

export default React.memo(RadioGroup);
