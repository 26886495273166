export type CounterSizeVariants = 'md' | 'sm';

export type CounterColorVariants =
    | 'white'
    | 'gray'
    | 'blue'
    | 'green'
    | 'orange'
    | 'red'
    | 'blue-v2';

export enum CounterTypographyVariants {
    sm = 'h4',
    md = 'h3',
}

export interface CounterProps {
    /** Size of the counter, default is set to md */
    size?: CounterSizeVariants;
    /** Color of the counter, default is set to gray */
    color?: CounterColorVariants;
    /** Value of the counter, default is set to 0 */
    value?: number;
    /** Overflow value of the counter, default is set to 999 */
    overflowCount?: number;
    /** Class name for counter */
    counterClassName?: string;
}
