import React from 'react';
import { RadioProps } from './types';
import styles from './Radio.module.scss';
import Typography from '@primitives/Typography';
import {
    TYPOGRAPHY_RADIO_VARIANT_MAP_HINT_MSG,
    TYPOGRAPHY_RADIO_VARIANT_MAP_LABEL,
} from './constants';
import cx from 'classnames';
import { getHintStyle } from './utils';

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
    ({ name, id, label, disabled, checked, variant = 'sm', hintText, ...restProps }, ref) => {
        const getHintMsg = () => {
            if (!hintText) return null;
            return (
                <Typography
                    className={hintClass}
                    variant={TYPOGRAPHY_RADIO_VARIANT_MAP_HINT_MSG[variant]}
                >
                    {hintText}
                </Typography>
            );
        };

        const getLabel = () => {
            if (!label) return null;
            return (
                <Typography
                    className={styles['radio__label']}
                    variant={TYPOGRAPHY_RADIO_VARIANT_MAP_LABEL[variant]}
                >
                    {label}
                </Typography>
            );
        };

        const radioClass = cx(styles['radio__box'], styles[`radio__box--${variant}`]);

        const hintClass = cx(styles['radio-content__hint'], getHintStyle(variant));
        const contentClass = cx(styles['radio-content'], {
            [styles['radio-content--disabled']]: disabled,
        });

        return (
            <div className={styles['container']}>
                <label className={contentClass}>
                    <div className={styles.radio}>
                        <input
                            ref={ref}
                            className={radioClass}
                            type="radio"
                            data-testid={id}
                            id={id}
                            name={name}
                            checked={checked}
                            disabled={disabled}
                            {...restProps}
                        />
                        {getLabel()}
                    </div>
                    {getHintMsg()}
                </label>
            </div>
        );
    },
);

export default React.memo(Radio);
