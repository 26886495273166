import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const UploadIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            width={svgWidth}
            height={svgHeight}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <mask
                id="mask0_3776_139216"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3776_139216)">
                <path
                    d="M12 15.9998C11.7167 15.9998 11.4793 15.9038 11.288 15.7118C11.096 15.5205 11 15.2831 11 14.9998V7.8498L9.125 9.7248C8.925 9.9248 8.69167 10.0248 8.425 10.0248C8.15833 10.0248 7.91667 9.91647 7.7 9.6998C7.5 9.4998 7.40433 9.26214 7.413 8.9868C7.421 8.71214 7.51667 8.48314 7.7 8.2998L11.3 4.6998C11.4 4.5998 11.5083 4.5288 11.625 4.4868C11.7417 4.44547 11.8667 4.4248 12 4.4248C12.1333 4.4248 12.2583 4.44547 12.375 4.4868C12.4917 4.5288 12.6 4.5998 12.7 4.6998L16.3 8.2998C16.5 8.4998 16.5957 8.73714 16.587 9.0118C16.579 9.28714 16.4833 9.51647 16.3 9.6998C16.1 9.8998 15.8627 10.0038 15.588 10.0118C15.3127 10.0205 15.075 9.9248 14.875 9.7248L13 7.8498V14.9998C13 15.2831 12.9043 15.5205 12.713 15.7118C12.521 15.9038 12.2833 15.9998 12 15.9998ZM6 19.9998C5.45 19.9998 4.97933 19.8041 4.588 19.4128C4.196 19.0208 4 18.5498 4 17.9998V15.9998C4 15.7165 4.09567 15.4788 4.287 15.2868C4.479 15.0955 4.71667 14.9998 5 14.9998C5.28333 14.9998 5.521 15.0955 5.713 15.2868C5.90433 15.4788 6 15.7165 6 15.9998V17.9998H18V15.9998C18 15.7165 18.096 15.4788 18.288 15.2868C18.4793 15.0955 18.7167 14.9998 19 14.9998C19.2833 14.9998 19.5207 15.0955 19.712 15.2868C19.904 15.4788 20 15.7165 20 15.9998V17.9998C20 18.5498 19.8043 19.0208 19.413 19.4128C19.021 19.8041 18.55 19.9998 18 19.9998H6Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default React.memo(UploadIcon);
