import { windowRequestAnimationFrame, GridColPrefix } from './constants';
import styles from './GridColumn/styles/GridColumn.module.scss';
import { columnVariantType, gridColAttribute } from './types';

let lastTime = 0;

type customFun = {
    (num: number): string;
};

const customFn = (callBack: customFun) => {
    const currTime = new Date().getTime();
    const timeToCall = Math.max(0, 16 - (currTime - lastTime));
    const id = setTimeout(() => {
        callBack(currTime + timeToCall);
    }, timeToCall);
    lastTime = currTime + timeToCall;
    return id;
};

export const requestAnimationFrame = (callback: customFun | any) => {
    let rAF = null;
    rAF = windowRequestAnimationFrame || customFn;
    return rAF(callback);
};

export const getGridColClass = (
    variant: columnVariantType | boolean,
    attribute: gridColAttribute,
) => {
    if (typeof variant === 'boolean') {
        return variant === true ? styles[`${GridColPrefix}-${attribute}`] : '';
    }
    return styles[`${GridColPrefix}-${attribute}-${variant}`];
};
