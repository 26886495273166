import React from 'react';
import Typography from '@primitives/Typography';
import CheckOutlineIcon from '@icons/CheckOutlineIcon';
import DashIcon from '@icons/DashIcon';
import { CheckboxProps } from './types';
import styles from './Checkbox.module.scss';
import cx from 'classnames';

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            disabled = false,
            title,
            subTitle,
            className,
            checkboxOuterWrapperClass = '',
            ...restProps
        },
        ref,
    ) => {
        return (
            <div
                className={cx(
                    styles['checkbox-outer-wrapper'],
                    {
                        [styles['disabled']]: disabled,
                    },
                    checkboxOuterWrapperClass,
                )}
            >
                <label className={styles['checkbox-inner-wrapper']}>
                    <input type="checkbox" ref={ref} disabled={disabled} {...restProps} />
                    <div className={styles['checkbox']}>
                        <CheckOutlineIcon
                            width={8}
                            height={8}
                            className={styles['checkbox--checkIcon']}
                            color={styles.naviCheckboxDefaultIconColor}
                        />
                        <DashIcon
                            width={8}
                            height={8}
                            className={styles['checkbox--indeterminateIcon']}
                            color={styles.naviCheckboxDefaultIconColor}
                        />
                    </div>
                </label>
                {title && (
                    <label className={styles['label']}>
                        <Typography variant="p3" className={styles['label--title']}>
                            {title}
                        </Typography>
                        {subTitle && (
                            <Typography variant="p4" className={styles['label--subtitle']}>
                                {subTitle}
                            </Typography>
                        )}
                    </label>
                )}
            </div>
        );
    },
);

export default Checkbox;
