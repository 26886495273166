import { FontType } from '../Typography/types';
import { RadioVariants } from './types';

export const TYPOGRAPHY_RADIO_VARIANT_MAP_LABEL: TypographyRadioVariantMap = {
    sm: 'p3',
    lg: 'p2',
};

export const TYPOGRAPHY_RADIO_VARIANT_MAP_HINT_MSG: TypographyRadioVariantMap = {
    sm: 'p4',
    lg: 'p3',
};

type TypographyRadioVariantMap = Record<RadioVariants, FontType>;
