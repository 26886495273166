import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const ArrowRightIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            width={svgWidth}
            height={svgHeight}
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.31963 8.98751L5.32546 5.98168C5.77807 5.52907 5.77807 4.79792 5.32546 4.3453L2.31963 1.33947C1.58848 0.608323 0.335083 1.13057 0.335083 2.16346L0.335083 8.17512C0.335083 9.20802 1.58848 9.71866 2.31963 8.98751Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(ArrowRightIcon);
