import React, { useMemo } from 'react';
import cx from 'classnames';

import { ChipProps, ChipWrapperProps } from './types';

import { getChipWrapperClass } from './utils';

import { chipLabelSizeToFontSize } from './constants';

import Typography from '@primitives/Typography';

import styles from './Chip.module.scss';

const Wrapper = (props: ChipWrapperProps) => {
    const { clickable, children, ...restProps } = props;
    const wrapperEleType = clickable ? 'button' : 'div';

    if (clickable) {
        return React.createElement(wrapperEleType, { type: 'button', ...restProps }, children);
    }

    return React.createElement(wrapperEleType, restProps, children);
};

const Chip: React.FC<ChipProps> = ({
    wrapperClasses,
    size = 'medium',
    label,
    labelClasses,
    LeftAdornment,
    onChipClick,
    RightAdornment,
    disabled,
    selected,
    name,
    value,
}) => {
    // Note: Pointer events are disabled when clickable on adornment to get button as the event target always (This was required to make it compatible with any external form libraries like Formik)
    const renderLabel = (): JSX.Element | false => {
        return (
            <Typography
                variant={chipLabelSizeToFontSize[size]}
                className={cx(styles.chip__label, labelClasses)}
            >
                {label}
            </Typography>
        );
    };

    // Note: Pointer events are disabled when clickable on adornment to get button as the event target always (This was required to make it compatible with any external form libraries like Formik)
    const getAdornmentComponent = (Adornment: React.ReactNode): JSX.Element | null => {
        if (!Adornment) {
            return null;
        }

        return (
            <Typography
                as="span"
                variant={chipLabelSizeToFontSize[size]}
                className={styles.chip__adornment}
            >
                {Adornment}
            </Typography>
        );
    };

    const clickable = useMemo(() => !!onChipClick && !disabled, [onChipClick, disabled]);

    return (
        <Wrapper
            name={name}
            value={value}
            className={cx(
                styles.chip,
                getChipWrapperClass(size),
                {
                    [styles['chip--clickable']]: clickable,
                    [styles['chip--disabled']]: disabled,
                    [styles['chip--selected']]: selected,
                },
                wrapperClasses,
            )}
            clickable={clickable}
            onClick={onChipClick} // FIXME: This has to fixed in upcoming releases where onRightAdornmentClick will be tied to
            data-testid="chip"
            disabled={disabled}
        >
            {getAdornmentComponent(LeftAdornment)}
            {renderLabel()}
            {getAdornmentComponent(RightAdornment)}
        </Wrapper>
    );
};

export default Chip;
