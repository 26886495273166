import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const AddIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={svgWidth}
            height={svgHeight}
            fill="none"
            viewBox="0 0 24 24"
            {...restProps}
        >
            <mask
                id="mask0_3169_137319"
                style={{ maskType: 'alpha' }}
                width="24"
                height="24"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_3169_137319)">
                <path fill={color} d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2z"></path>
            </g>
        </svg>
    );
};

export default React.memo(AddIcon);
