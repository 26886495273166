import React, { forwardRef, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { AgGridReact } from 'ag-grid-react';
import { AgTableProps } from './types';
import { sortAscending, sortDescending, sortUnSort } from './icons';
import LoadingOverlayTemplateIcon from '@icons/LoadingOverlayTemplateIcon';
import styles from './AgTable.module.scss';
import { GridReadyEvent, IHeaderParams, RowClassParams, RowStyle } from 'ag-grid-community';
import AgTableSortHeader from './AgTableSortHeader';
import NoRowsTableTemplateIcon from '@icons/NoRowsTableTemplateIcon';

const AgTable = forwardRef<AgGridReact | null, AgTableProps>(
    (
        {
            rowData,
            columnDefs,
            defaultColDef,
            defaultColGroupDef,
            columnTypes,
            rowHeight = 56,
            headerHeight = 56,
            groupHeaderHeight,
            tooltipShowDelay = 180,
            isFullWidthRow,
            fullwidthCellRenderer,
            animateRows,
            theme = 'alpine',
            themeOverriderClass = '',
            unSortIcon = true,
            suppressRowHoverHighlight = false,
            columnHoverHighlight = false,
            alternateRowColor = '',
            noRowsOverlayComponent = () => <NoRowsTableTemplateIcon width={86} height={80} />,
            noRowsOverlayComponentParams,
            loadingOverlayComponent = () => <LoadingOverlayTemplateIcon width={48} height={48} />,
            loadingOverlayComponentParams,
            PaginationComponent,
            onServerSortChanged,
            icons,
            enableClientSideSort = false,
            sizeColumnsToFit,
            style = {},
            domLayout,
            onRowClicked,
            rowClassRules,
            getAgRowStyle,
            onGridReady,
            paginationWrapperClasses,
            ...rest
        },
        ref,
    ) => {
        const tableStyle = cx(
            { [`ag-theme-${theme} ag-theme-${theme}-custom`]: !!theme },
            { [`ag-theme-${themeOverriderClass}`]: !!themeOverriderClass },
            { ['remove-border-btm-radius-on-pagination']: !!PaginationComponent },
        );

        const gridStyle: React.CSSProperties = useMemo(
            () => ({
                height: PaginationComponent ? 'calc(100% - 56px)' : '100%',
                width: '100%',
                position: PaginationComponent ? 'relative' : 'static',
                ...style,
            }),
            [],
        );

        const getRowStyle = useCallback((params: RowClassParams): RowStyle | undefined => {
            if (params.node.rowIndex! % 2 === 0) {
                return { background: alternateRowColor };
            }
            if (getAgRowStyle) getAgRowStyle(params);
        }, []);

        const iconsHandler = {
            ...icons,
            sortAscending: () => sortAscending(7, 14),
            sortDescending: () => sortDescending(7, 14),
            sortUnSort: () => sortUnSort(7, 14),
        };

        const components = useMemo(() => {
            if (onServerSortChanged) {
                return {
                    agColumnHeader: (params: IHeaderParams) => (
                        <AgTableSortHeader {...params} onSortChanged={onServerSortChanged} />
                    ),
                };
            }
        }, []);

        const onGridReadyHandler = (event: GridReadyEvent<any>) => {
            if (onGridReady) onGridReady(event);
            if (sizeColumnsToFit) event.api.sizeColumnsToFit();
        };

        return (
            <div className={tableStyle} style={gridStyle}>
                {!!PaginationComponent && (
                    <div
                        className={cx(
                            styles['pagination-style__wrapper'],
                            paginationWrapperClasses,
                        )}
                    >
                        {PaginationComponent}
                    </div>
                )}
                <AgGridReact
                    ref={ref}
                    rowHeight={rowHeight}
                    headerHeight={headerHeight}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    defaultColGroupDef={defaultColGroupDef}
                    columnTypes={columnTypes}
                    groupHeaderHeight={groupHeaderHeight}
                    tooltipShowDelay={tooltipShowDelay}
                    multiSortKey={'ctrl'}
                    isFullWidthRow={isFullWidthRow}
                    fullWidthCellRenderer={fullwidthCellRenderer}
                    animateRows={animateRows}
                    icons={iconsHandler}
                    unSortIcon={unSortIcon}
                    suppressRowHoverHighlight={suppressRowHoverHighlight}
                    columnHoverHighlight={columnHoverHighlight}
                    noRowsOverlayComponent={noRowsOverlayComponent}
                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    loadingOverlayComponent={loadingOverlayComponent}
                    loadingOverlayComponentParams={loadingOverlayComponentParams}
                    components={enableClientSideSort ? {} : components}
                    getRowStyle={getRowStyle}
                    rowClassRules={rowClassRules}
                    domLayout={domLayout}
                    enableCellTextSelection
                    onRowClicked={onRowClicked}
                    onGridReady={onGridReadyHandler}
                    {...rest}
                />
            </div>
        );
    },
);
export default AgTable;
