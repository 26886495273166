import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const LoadingIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviLoaderDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={svgWidth}
            height={svgHeight}
            viewBox="0 0 24 30"
            {...restProps}
        >
            <rect x="0" y="10" width="4" height="10" fill={color} opacity="0.2">
                <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="0.2; 1; .2"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="height"
                    attributeType="XML"
                    values="10; 20; 10"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="y"
                    attributeType="XML"
                    values="10; 5; 10"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect x="8" y="10" width="4" height="10" fill={color} opacity="0.2">
                <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="0.2; 1; .2"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="height"
                    attributeType="XML"
                    values="10; 20; 10"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="y"
                    attributeType="XML"
                    values="10; 5; 10"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect x="16" y="10" width="4" height="10" fill={color} opacity="0.2">
                <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="0.2; 1; .2"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="height"
                    attributeType="XML"
                    values="10; 20; 10"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="y"
                    attributeType="XML"
                    values="10; 5; 10"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </rect>
        </svg>
    );
};

export default React.memo(LoadingIcon);
