import { TabItemProps } from '@components/Tabs/types';
import React from 'react';

const TabItemComponent: React.FC<TabItemProps> = ({ children }) => {
    return <div>{children}</div>;
};

const TabItem: React.FC<TabItemProps> = React.memo(TabItemComponent);

TabItem.displayName = 'TabItem';

export default TabItem;
