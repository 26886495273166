import { FontType } from '@primitives/Typography/types';
import { TextAreaSizeVariant } from './types';

export enum States {
    default = 'default',
    filled = 'filled',
    active = 'active',
    error = 'error',
    success = 'success',
    disabled = 'disabled',
}

export enum HelperTextVariants {
    error = 'error',
    success = 'success',
}

export enum TextAreaClasses {
    base = 'textarea',
    wrapper = 'textarea__wrapper',
    container = 'textarea__wrapper--container',
    labelWrapper = 'textarea__label-wrapper',
    label = 'textarea__label-wrapper--label',
    required = 'textarea__label-wrapper--required',
    labelIcon = 'textarea__label-wrapper--icon',
    helperTextWrapper = 'textarea__helper-text-wrapper',
    helperTextIcon = 'textarea__helper-text-wrapper--icon',
    hintTextWrapper = 'textarea__hint-text-wrapper',
    hintTextMsgs = 'textarea__hint-text-wrapper-text-msgs',
    hintTextIcon = 'textarea__hint-text-wrapper-text-msgs--icon',
    fullWidth = 'textarea__wrapper--full-width',
}

export const textToFontType: Record<TextAreaSizeVariant, FontType> = {
    small: 'p5',
    medium: 'p4',
    large: 'p3',
};

export const sizeToFontType: Record<TextAreaSizeVariant, FontType> = {
    small: 'p4',
    medium: 'p3',
    large: 'p2',
};
