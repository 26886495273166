export enum inputStates {
    default = 'default',
    filled = 'filled',
    active = 'active',
    error = 'error',
    success = 'success',
    disabled = 'disabled',
}

export enum BorderLessInputClasses {
    base = 'border-less-input',
    wrapper = 'border-less-input__input-wrapper',
    inputContainer = 'border-less-input__input-wrapper--container',
    label = 'border-less-input__input-label',
    helperTextWrapper = 'border-less-input__helper-text-wrapper',
    helperTextMsgs = 'border-less-input__helper-text-wrapper-text-msgs',
    helperTextIcon = 'border-less-input__helper-text-wrapper-text-msgs--icon',
    hintTextWrapper = 'border-less-input__hint-text-wrapper',
    hintTextMsgs = 'border-less-input__hint-text-wrapper-text-msgs',
    hintTextIcon = 'border-less-input__hint-text-wrapper-text-msgs--icon',
    inputAdornmentClass = 'border-less-input__adornment-class',
}

export enum HelperTextVariants {
    error = 'error',
    success = 'success',
}

export const BorderLessInputActionTypes = {
    SET_INPUT_LABEL: 'SET_INPUT_LABEL',
    SET_INPUT_STATUS: 'SET_INPUT_STATUS',
    SET_INPUT_VALUE: 'SET_INPUT_VALUE',
    SET_LABEL_VISIBILITY: 'SET_LABEL_VISIBILITY',
    SET_CLEAR_ICON_VISIBILITY: 'SET_CLEAR_ICON_VISIBILITY',
    HANDLE_ON_BLUR: 'HANDLE_ON_BLUR',
    HANDLE_ON_FOCUS: 'HANDLE_ON_FOCUS',
    HANDLE_VALUE_CHANGE: 'HANDLE_VALUE_CHANGE',
};
