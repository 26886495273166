import React from 'react';
import cx from 'classnames';

import Typography from '@primitives/Typography';
import styles from './BorderLessInput.module.scss';
import CloseIcon from '@icons/CloseIcon';
import { BorderLessInputClasses, inputStates } from './constants';
import { BorderLessInputProps } from './types';
import {
    getBorderLessVariantClass,
    getInputStatus,
    getHelperTextVariantClass,
    getFullWidthVariantClass,
    BorderLessInputReducer,
    getInputAdornmentClass,
} from './utils';

const BorderLessInput = React.forwardRef<HTMLInputElement, BorderLessInputProps>(
    (
        {
            containerClassName = '',
            InputAdornment = '',
            hintMsg = '',
            hintMsgIcon: HintMsgIcon = null,
            inputLabel = '',
            Icon = null,
            success = false,
            error = false,
            value = '',
            disabled = false,
            fullWidth = false,
            hideClearIcon = false,
            onFocus,
            onBlur,
            onChange,
            placeholder,
            helperTextClasses,
            hintTextClasses,
            onClearIconClick = null,
            ...restProps
        },
        ref,
    ) => {
        const finalInputStatus = getInputStatus(success, error, disabled);

        const initialState = {
            showLabel: false,
            inputPlaceholder: inputLabel,
            showClearIcon: false,
            inputStatus: finalInputStatus,
            inputValue: value,
        };

        const [state, dispatch] = React.useReducer(BorderLessInputReducer, initialState);

        React.useEffect(() => {
            const status = getInputStatus(success, error, disabled);
            const isInputPresent = value !== '' || state.inputValue !== '';
            dispatch({
                type: 'HANDLE_VALUE_CHANGE',
                payload: {
                    showLabel: isInputPresent,
                    showClearIcon: isInputPresent && !disabled,
                    inputStatus: status,
                },
            });
        }, [success, error, disabled]);

        React.useEffect(() => {
            dispatch({
                type: 'HANDLE_VALUE_CHANGE',
                payload: {
                    inputValue: value,
                },
            });
        }, [value]);

        const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
            if (state.inputStatus === inputStates.active) {
                dispatch({
                    type: 'SET_INPUT_STATUS',
                    payload: finalInputStatus,
                });
            }

            if (state.inputValue === '') {
                dispatch({
                    type: 'HANDLE_ON_BLUR',
                    payload: {
                        showLabel: false,
                        inputPlaceholder: inputLabel,
                        showClearIcon: false,
                    },
                });
            }

            /* To pass the current event to the consumer */
            if (onBlur) {
                onBlur(event);
            }
        };

        const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
            if (state.inputStatus !== inputStates.disabled) {
                dispatch({
                    type: 'HANDLE_ON_FOCUS',
                    payload: {
                        inputStatus: inputStates.active,
                        showLabel: true,
                        inputPlaceholder: '',
                        showClearIcon: true,
                    },
                });
            }

            /* To pass the current event to the consumer */
            if (onFocus) {
                onFocus(event);
            }
        };

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;
            dispatch({
                type: 'SET_INPUT_VALUE',
                payload: {
                    inputValue: value,
                },
            });

            /* To pass the current event to the consumer */
            if (onChange) {
                onChange(event);
            }
        };

        const handleClearInput = (): void => {
            if (state.inputValue) {
                dispatch({
                    type: 'SET_INPUT_VALUE',
                    payload: {
                        inputValue: '',
                        showLabel: false,
                        showClearIcon: false,
                        inputPlaceholder: inputLabel,
                    },
                });
            }

            if (onClearIconClick) {
                onClearIconClick();
            }
        };

        const getLabelComponent = (): JSX.Element => {
            if (state.showLabel) {
                return (
                    <label>
                        <Typography
                            variant="p5"
                            className={styles[`${BorderLessInputClasses.label}`]}
                        >
                            {inputLabel}
                        </Typography>
                    </label>
                );
            }
            return <></>;
        };

        const getHelperTextComponent = (): JSX.Element => {
            if (
                (typeof success === 'string' && success !== '') ||
                (typeof error === 'string' && error !== '')
            ) {
                return (
                    <div
                        className={cx(
                            styles[`${BorderLessInputClasses.helperTextWrapper}`],
                            helperTextClasses,
                        )}
                    >
                        {Icon !== null && (
                            <span className={styles[`${BorderLessInputClasses.helperTextIcon}`]}>
                                {Icon}
                            </span>
                        )}
                        <Typography
                            variant="p5"
                            as="span"
                            className={getHelperTextVariantClass(success, error)}
                        >
                            {success || error}
                        </Typography>
                    </div>
                );
            }
            return <></>;
        };

        const getHintMsgComponent = (): JSX.Element => {
            if (hintMsg !== '') {
                return (
                    <div
                        className={cx(
                            styles[`${BorderLessInputClasses.hintTextWrapper}`],
                            hintTextClasses,
                        )}
                    >
                        {HintMsgIcon !== null && (
                            <span className={styles[`${BorderLessInputClasses.hintTextIcon}`]}>
                                {HintMsgIcon}
                            </span>
                        )}
                        <Typography
                            variant="p5"
                            as="span"
                            className={styles[`${BorderLessInputClasses.hintTextMsgs}`]}
                        >
                            {hintMsg}
                        </Typography>
                    </div>
                );
            }
            return <></>;
        };

        const getClearIcon = (): JSX.Element => {
            if (state.showClearIcon && !hideClearIcon) {
                return (
                    <CloseIcon
                        onClick={handleClearInput}
                        cursor="pointer"
                        data-testid="borderless-clear-icon"
                    />
                );
            }
            return <></>;
        };

        const getInputAdornment = (): JSX.Element => {
            if (typeof InputAdornment === 'string' && InputAdornment !== '') {
                return (
                    <Typography
                        variant="p2"
                        as="span"
                        className={cx(
                            styles[`${BorderLessInputClasses.inputAdornmentClass}`],
                            getInputAdornmentClass(state.inputStatus),
                        )}
                    >
                        {InputAdornment}
                    </Typography>
                );
            }

            if (InputAdornment) {
                return (
                    <div
                        className={cx(
                            styles[`${BorderLessInputClasses.inputAdornmentClass}`],
                            getInputAdornmentClass(state.inputStatus),
                        )}
                    >
                        {InputAdornment}
                    </div>
                );
            }

            return <></>;
        };

        const inputWrapperStyle = React.useMemo(() => {
            return cx(
                styles[`${BorderLessInputClasses.wrapper}`],
                getBorderLessVariantClass(state.inputStatus),
                getFullWidthVariantClass(fullWidth),
                containerClassName,
            );
        }, [state.inputStatus]);

        return (
            <div data-testid="borderless-input">
                {getLabelComponent()}
                <div className={inputWrapperStyle}>
                    {getInputAdornment()}
                    <Typography
                        variant="p2"
                        className={styles[`${BorderLessInputClasses.inputContainer}`]}
                    >
                        <input
                            ref={ref}
                            value={state.inputValue}
                            onFocus={handleOnFocus}
                            onBlur={handleOnBlur}
                            onChange={handleChange}
                            placeholder={state.inputPlaceholder}
                            {...restProps}
                            aria-label="input-box"
                            disabled={disabled}
                        />
                    </Typography>
                    {getClearIcon()}
                </div>
                {getHelperTextComponent()}
                {getHintMsgComponent()}
            </div>
        );
    },
);

export default BorderLessInput;
