import React from 'react';
import { createPortal } from 'react-dom';
import { ReactPortalProps } from './types';

const createWrapperElementForPortal = (wrapperId: string): HTMLDivElement => {
    const wrapperElement = document.createElement('div');
    wrapperElement.id = wrapperId;
    document.body.appendChild(wrapperElement);
    return wrapperElement;
};

const ReactPortal: React.FC<ReactPortalProps> = ({ children, wrapperId }) => {
    const [wrapperElement, setWrapperElement] = React.useState<HTMLElement | null>(null);

    React.useLayoutEffect(() => {
        let element = document.getElementById(wrapperId);
        let wrapperCreated = false;

        if (!element) {
            element = createWrapperElementForPortal(wrapperId);
            wrapperCreated = true;
        }
        setWrapperElement(element);

        return () => {
            if (wrapperCreated && element?.parentNode) {
                element.parentNode.removeChild(element);
            }
        };
    }, [wrapperId]);

    if (wrapperElement === null) return null;
    return createPortal(children, wrapperElement);
};

export default ReactPortal;
