import React from 'react';

import { customFunType, validationType } from './types';

export const useAnimationFrame = (callback: customFunType) => {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = React.useRef<number | undefined | any>();
    const previousTimeRef = React.useRef<number | undefined>();

    const animate = (time: number) => {
        if (previousTimeRef.current != undefined) {
            const deltaTime = time - previousTimeRef.current;
            callback(deltaTime);
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    };

    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []); // Make sure the effect runs only once
};

export const useGridRAF = (
    containerColRef: React.RefObject<HTMLDivElement>,
    validationObj: validationType,
    isGridColumn = false,
) => {
    const { regex, errorMessage } = validationObj;
    const { childNodes = [] } = containerColRef?.current || {};
    const arrayOfChildNodes = [...childNodes];
    useAnimationFrame(() => {
        arrayOfChildNodes.forEach((item) => {
            const condition = isGridColumn
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore:next-line
                  !regex.test(item?.className)
                : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore:next-line
                  regex.test(item?.className); //TODO: typescript error should be fixed
            if (condition) {
                throw Error(errorMessage);
                //TODO: This will be un-commented, once we have enabled the env variables.
                // if (process.env?.STORYBOOK_ENV === 'development')
                //   throw Error(errorMessage);
            }
        });
    });
};
