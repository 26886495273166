import React from 'react';
import Typography from '../Typography';
import { TAG_SIZE_TYPOGRAPHY_MAP } from './constants';
import { TagProps } from './types';
import styles from './Tag.module.scss';
import cx from 'classnames';
import { getTagSizeAdornmentStyle, getTagTextColor, getTagVariantStyle } from './utils';

const Tag: React.FC<TagProps> = ({
    label,
    endAdornment,
    startAdornment,
    size = 'md',
    color = 'primary',
    variant = 'opaque',
}) => {
    const tagClass = cx(
        styles.tag,
        styles[`tag--${size}`],
        styles[`tag--${color}`],
        getTagVariantStyle(color, variant),
        getTagSizeAdornmentStyle(size, startAdornment, endAdornment),
    );

    return (
        <span className={tagClass}>
            {startAdornment ?? null}
            <Typography
                color={getTagTextColor(color, variant)}
                variant={TAG_SIZE_TYPOGRAPHY_MAP[size]}
            >
                {label}
            </Typography>
            {endAdornment ?? null}
        </span>
    );
};

export default React.memo(Tag);
