import React, { useEffect, useState } from 'react';
import Filter from '@components/Filter';
import { SelectPickerOptionProps } from '@components/SelectPicker/types';
import { DOTS, usePagination } from '@hooks/usePagination';
import Typography from '@primitives/Typography';
import styles from './Pagination.module.scss';
import { PaginationDetailsProps, PaginationProps } from './types';
import ArrowLeftSolidIcon from '@icons/ArrowLeftSolidIcon';
import ArrowRightSolidIcon from '@icons/ArrowRightSolidIcon';
import cx from 'classnames';

const Pagination: React.FC<PaginationProps> = ({
    compactMode,
    currentPage = 1,
    className,
    hidePageSizeOptions,
    onPageChange,
    onPageSizeChange,
    pageSizeOptions = [10, 20, 50, 100],
    pageSize = pageSizeOptions[0],
    siblingCount = 1,
    hideButtonLabels,
    totalCount,
    containerClasses,
    hidePageOptions = false,
}) => {
    const [paginationDetails, setPaginationDetails] = useState<PaginationDetailsProps>({
        page: currentPage,
        pageSize: pageSizeOptions[0],
    });

    useEffect(() => {
        setPaginationDetails({ ...paginationDetails, page: currentPage });
    }, [currentPage]);

    useEffect(() => {
        setPaginationDetails({ ...paginationDetails, pageSize: pageSize });
    }, [pageSize]);

    const paginationRange = usePagination({
        ...paginationDetails,
        totalCount,
        siblingCount,
    });

    if (paginationDetails.page === 0 || paginationRange.length < 1) {
        return null;
    }

    const onNext = () => {
        const { page } = paginationDetails;
        setPaginationDetails({ ...paginationDetails, page: page + 1 });
        if (onPageChange) {
            onPageChange(page + 1);
        }
    };

    const onPrevious = () => {
        const { page } = paginationDetails;
        setPaginationDetails({ ...paginationDetails, page: page - 1 });
        if (onPageChange) {
            onPageChange(page - 1);
        }
    };

    const onPageChangeHandler = (page: number) => {
        setPaginationDetails({ ...paginationDetails, page });
        if (onPageChange) {
            onPageChange(page);
        }
    };

    const onPageSizeChangeHandler = (value: number) => {
        setPaginationDetails({ ...paginationDetails, pageSize: value });
        if (onPageSizeChange) {
            onPageSizeChange(value);
        }
    };

    const lastPage = paginationRange[paginationRange.length - 1];

    const renderPageSizeOptions = (): JSX.Element | null => {
        if (compactMode || hidePageSizeOptions) {
            return null;
        }
        const pageSizeDropdownOptions = pageSizeOptions.map(
            (val) =>
                ({
                    label: `${val}`,
                    value: val,
                } as SelectPickerOptionProps),
        );

        return (
            <Typography variant="p4" className={styles['pageLabel']}>
                Showing{' '}
                <Filter
                    title={`${paginationDetails.pageSize}`}
                    options={pageSizeDropdownOptions}
                    isSingleSelect
                    onSelectionChange={(option) => {
                        if (!Array.isArray(option) && typeof option.value === 'number')
                            onPageSizeChangeHandler(option.value);
                    }}
                    pickerWrapperClass={styles['page-size-option-picker']}
                    filterClass={styles['page-size-option-filter']}
                    titleClass={styles['page-size-option-filter-title']}
                />{' '}
                results per page
            </Typography>
        );
    };

    const renderCurrentPageDetails = (): JSX.Element => {
        return (
            <Typography variant="p4" className={styles['pageLabel']}>
                Page {paginationDetails.page} of {lastPage}
            </Typography>
        );
    };

    const renderPageNumbers = (): JSX.Element[] | null => {
        if (compactMode) {
            return null;
        }
        return paginationRange.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
                return (
                    <li
                        key={`pagination-range-item-${index}`}
                        className={styles['pagination--item--dots']}
                    >
                        <Typography variant="p4" className={styles['pagination--item--dots']}>
                            ...
                        </Typography>
                    </li>
                );
            }

            return (
                <li
                    key={`pagination-range-item-${index}`}
                    className={cx(styles['pagination--item'], {
                        [styles['selected']]: pageNumber === paginationDetails.page,
                    })}
                    onClick={() => onPageChangeHandler(pageNumber)}
                >
                    <Typography variant="p4" className={styles['label']}>
                        {pageNumber}
                    </Typography>
                </li>
            );
        });
    };

    return (
        <div className={cx(styles['pagination-container'], containerClasses)}>
            {renderPageSizeOptions()}
            {!hidePageOptions && (
                <ul className={cx(styles['pagination'], className)}>
                    {renderCurrentPageDetails()}
                    <li
                        className={cx(styles['pagination--item'], {
                            [styles['pagination--item--disabled']]: paginationDetails.page === 1,
                            [styles['pagination--item--compact']]: compactMode && !hideButtonLabels,
                        })}
                        onClick={onPrevious}
                    >
                        <ArrowLeftSolidIcon width={6} height={10} />
                        {compactMode && !hideButtonLabels && (
                            <Typography variant="p4">Prev</Typography>
                        )}
                    </li>
                    {renderPageNumbers()}
                    <li
                        className={cx(styles['pagination--item'], {
                            [styles['pagination--item--disabled']]:
                                paginationDetails.page === lastPage,
                            [styles['pagination--item--compact']]: compactMode && !hideButtonLabels,
                        })}
                        onClick={onNext}
                    >
                        {compactMode && !hideButtonLabels && (
                            <Typography variant="p4">Next</Typography>
                        )}
                        <ArrowRightSolidIcon width={6} height={10} />
                    </li>
                </ul>
            )}
        </div>
    );
};

export default Pagination;
