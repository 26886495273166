import React from 'react';
import cx from 'classnames';
import ReactPortal from '../ReactPortal';
import CloseIcon from '@icons/CloseIcon';
import { DomElementIds } from '@constants';
import { BottomSheetProps } from './types';
import styles from './BottomSheet.module.scss';
import colors from '../../styles/colors.module.scss';

const BottomSheet: React.FC<BottomSheetProps> = ({
    open = false,
    onClose,
    children,
    fullScreen = false,
    showCloseIcon = false,
    usePadding = true,
    fixedHeight,
    className,
    ...restProps
}) => {
    const rootElement = document.getElementById('root');
    const { bottomSheetPortalId } = DomElementIds;
    const bottomSheetContainerStyles = cx(styles['bottom-sheet-container'], {
        [styles['is-open']]: open,
    });

    const bottomSheetStyles = cx(className, styles['bottom-sheet'], {
        [styles['bottom-sheet--full-screen']]: fullScreen,
        [styles['bottom-sheet--with-padding']]: usePadding,
    });

    const handleClose = (): void => {
        if (rootElement?.style?.overflow === 'hidden') {
            rootElement.style.overflow = 'initial';
        }
        onClose();
    };

    if (open) {
        if (rootElement?.style?.overflow === '') {
            rootElement.style.overflow = 'hidden';
        }
    }

    const renderCloseIcon = (): JSX.Element | false => {
        return (
            showCloseIcon && (
                <CloseIcon
                    color={colors['--navi-color-gray-c1']}
                    size="sm"
                    className={styles['bottom-sheet__close-icon']}
                    onClick={handleClose}
                />
            )
        );
    };

    return (
        <ReactPortal wrapperId={bottomSheetPortalId}>
            <div className={bottomSheetContainerStyles} onClick={handleClose}>
                <div
                    className={bottomSheetStyles}
                    onClick={(e) => e.stopPropagation()}
                    style={{ height: `${fixedHeight}%` }}
                    {...restProps}
                >
                    {renderCloseIcon()}
                    {children}
                </div>
            </div>
        </ReactPortal>
    );
};

export default BottomSheet;
