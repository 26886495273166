import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const LoadingOverlayTemplateIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            width={svgWidth}
            height={svgHeight}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <mask
                id="mask0_1406_73716"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="48"
                height="48"
            >
                <rect width="48" height="48" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1406_73716)">
                <path
                    d="M24.1 40C19.6333 40 15.8333 38.45 12.7 35.35C9.56667 32.25 8 28.4667 8 24V23.65L4.8 26.85L2 24.05L10 16.05L18 24.05L15.2 26.85L12 23.65V24C12 27.3333 13.1753 30.1667 15.526 32.5C17.8753 34.8333 20.7333 36 24.1 36C24.9667 36 25.8167 35.9 26.65 35.7C27.4833 35.5 28.3 35.2 29.1 34.8L32.1 37.8C30.8333 38.5333 29.5333 39.0833 28.2 39.45C26.8667 39.8167 25.5 40 24.1 40ZM38 31.95L30 23.95L32.8 21.15L36 24.35V24C36 20.6667 34.8253 17.8333 32.476 15.5C30.1253 13.1667 27.2667 12 23.9 12C23.0333 12 22.1833 12.1 21.35 12.3C20.5167 12.5 19.7 12.8 18.9 13.2L15.9 10.2C17.1667 9.46667 18.4667 8.91667 19.8 8.55C21.1333 8.18333 22.5 8 23.9 8C28.3667 8 32.1667 9.55 35.3 12.65C38.4333 15.75 40 19.5333 40 24V24.35L43.2 21.15L46 23.95L38 31.95Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default React.memo(LoadingOverlayTemplateIcon);
