import React, { useEffect, useRef, useState } from 'react';
import { FilterProps } from './types';
import styles from './Filter.module.scss';
import Typography from '@primitives/Typography';
import SelectPicker from '@components/SelectPicker';
import { SelectPickerOptionProps, SelectPickerValue } from '@components/SelectPicker/types';
import ArrowDownSolidIcon from '@icons/ArrowDownSolidIcon';
import ArrowUpSolidIcon from '@icons/ArrowUpSolidIcon';
import CancelIcon from '@icons/CancelIcon';
import { useOutsideClick } from '@hooks';
import cx from 'classnames';

const Filter: React.FC<FilterProps> = ({
    title = 'Filter',
    options = [],
    selectedValues,
    onSelectionChange,
    filterIcon,
    isSingleSelect = false,
    containerClass = '',
    containerAppliedClass = '',
    filterClass = '',
    titleClass = '',
    pickerWrapperClass = '',
    iconContainerClass = '',
    onCancelClick = null,
    ...restProps
}) => {
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [selectedFilters, setSelectedFilters] = useState<Array<SelectPickerValue>>(
        selectedValues || [],
    );
    const filterRef = useRef(null);
    const selectedFiltersWhenPickerIsOpened = useRef<SelectPickerOptionProps[]>([]);

    useEffect(() => {
        if (showPicker) {
            selectedFiltersWhenPickerIsOpened.current = options.filter((option) =>
                selectedFilters.includes(option.value),
            );
        }
    }, [showPicker]);

    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    const onFilterChangeHandler = (val: SelectPickerOptionProps[] | SelectPickerOptionProps) => {
        if (Array.isArray(val)) {
            const selectedOptions = val.map((option) => option?.value);
            setSelectedFilters(selectedOptions);
        } else if (val && typeof val === 'object') {
            setShowPicker(false);
            setSelectedFilters([val?.value]);
            onSelectionChange(val);
        }
    };

    const removeFilters = (e: React.MouseEvent) => {
        e.stopPropagation();
        setSelectedFilters([]);
        onSelectionChange([]);
        selectedFiltersWhenPickerIsOpened.current = [];
    };

    const handleCancel = (): void => {
        setShowPicker(false);
        setSelectedFilters(
            selectedFiltersWhenPickerIsOpened.current.map((selection) => selection.value),
        );
        if (onCancelClick) {
            onCancelClick();
        }
    };

    const handleApply = (values: SelectPickerOptionProps[]): void => {
        togglePicker();
        onSelectionChange(values);
    };

    useOutsideClick(filterRef, () => {
        if (showPicker) {
            // Only removing the picker incase of single select.
            if (isSingleSelect) {
                setShowPicker(false);
                return;
            }
            handleCancel();
        }
    });

    const ArrowIcon = showPicker ? ArrowUpSolidIcon : ArrowDownSolidIcon;

    const isShowCancelOption = !!selectedFilters.length && !isSingleSelect;

    const appliedClass = cx(styles['filter--applied'], containerAppliedClass);

    return (
        <div className={cx(styles['filter-container'], containerClass)} ref={filterRef}>
            <div
                className={cx(
                    styles['filter'],
                    {
                        [appliedClass]: selectedFilters.length > 0,
                        [styles['filter--active']]: showPicker,
                        // [styles['filter--single-select']]: isSingleSelect,
                    },
                    filterClass,
                )}
                onClick={togglePicker}
                data-testid="filter-dropdown-toggler"
            >
                {filterIcon}
                <Typography
                    variant="p3"
                    className={cx(
                        styles['filter--title'],
                        {
                            [styles['filter--title--active']]: selectedFilters.length > 0,
                        },
                        titleClass,
                    )}
                >
                    {title}{' '}
                    {!isSingleSelect && selectedFilters.length > 0 && `(${selectedFilters.length})`}
                </Typography>
                <div className={iconContainerClass}>
                    <ArrowIcon width={9} />
                </div>
                {isShowCancelOption && <CancelIcon width={16} onClick={removeFilters} />}
            </div>
            {showPicker && (
                <SelectPicker
                    multiSelect={!isSingleSelect}
                    options={options}
                    selectedValues={selectedFilters}
                    selectedValue={selectedFilters?.[0]}
                    onSelectionChange={onFilterChangeHandler}
                    wrapperClasses={cx(styles['picker'], pickerWrapperClass)}
                    handleApplyClick={handleApply}
                    handleCancelClick={handleCancel}
                    data-testid="filter-dropdown"
                    {...restProps}
                />
            )}
        </div>
    );
};

export default Filter;
