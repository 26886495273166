import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const CheckOutlineIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            width={svgHeight}
            height={svgWidth}
            viewBox="0 0 8 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 0.800003C6.724 0.800003 6.474 0.912003 6.293 1.093L5.293 2.093L3 4.386L1.707 3.093C1.526 2.912 1.276 2.8 1 2.8C0.448 2.8 0 3.248 0 3.8C0 4.076 0.112 4.326 0.293 4.507L2.293 6.507C2.474 6.688 2.724 6.8 3 6.8C3.276 6.8 3.526 6.688 3.707 6.507L6.707 3.507L7.707 2.507C7.888 2.326 8 2.076 8 1.8C8 1.2475 7.552 0.800003 7 0.800003Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(CheckOutlineIcon);
