import React from 'react';
import { BorderedInput, BorderLessInput } from '@primitives';
import SearchSelectPopUp from '@components/SearchSelectPopUp';
import { MobileSelectInputProps } from './types';

const MobileSelectInput = React.forwardRef<HTMLInputElement, MobileSelectInputProps>(
    (
        {
            value,
            placeholder = '',
            searchResultArray,
            onSearchChange = undefined,
            setSelectedValue,
            popUpTitle = '',
            useBorderedInput = false,
            error = false,
            success = false,
            fullWidth = false,
            inputLabel = '',
            ...restProps
        },
        ref,
    ) => {
        const [openSelectPopUp, setOpenSelectPopUp] = React.useState(false);
        const [displayValue, setDisplayValue] = React.useState('');

        const handleValueSelection = (index: number) => {
            setSelectedValue(index);
            setOpenSelectPopUp(false);
        };

        React.useEffect(() => {
            if (!value) {
                setDisplayValue('');
            }

            for (let i = 0; i < searchResultArray.length; i++) {
                if (value === searchResultArray[i].value) {
                    setDisplayValue(searchResultArray[i].label);
                    break;
                }
            }
        }, [value]);

        const getTypeOfInput = (): JSX.Element => {
            const inputProps = {
                value: displayValue,
                onClick: () => setOpenSelectPopUp(true),
                placeholder,
                error,
                success,
                ref,
                fullWidth,
                inputLabel,
                ...restProps,
            };

            return useBorderedInput ? (
                <BorderedInput {...inputProps} readOnly />
            ) : (
                <BorderLessInput {...inputProps} readOnly />
            );
        };

        return (
            <>
                {getTypeOfInput()}
                <SearchSelectPopUp
                    title={popUpTitle}
                    open={openSelectPopUp}
                    onClose={() => setOpenSelectPopUp(false)}
                    onSearchChange={onSearchChange}
                    handleValueSelection={handleValueSelection}
                    results={searchResultArray}
                />
            </>
        );
    },
);

export default MobileSelectInput;
