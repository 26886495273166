import { IsWindow } from '@constants/Components';

export const windowRequestAnimationFrame = IsWindow && window.requestAnimationFrame;
export const windowCancelAnimationFrame = IsWindow && window.cancelAnimationFrame;

export const vendorsPrefixes = ['ms', 'moz', 'webkit', 'o'];

export enum GridColAttributes {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
}

export const GridContainerPrefix = 'grid-container';

export const GridColPrefix = 'grid-col';

export const GridRowPrefix = 'grid-row';

export const gridLayoutValidation = {
    gridColumn: {
        regex: /(^|\s)grid-col($|[\s])/,
        errorMessage: `\`GridColumn\` cannot have \`GridColumn\` as immediate child. 
        Use \`GridRow\` in between when nesting two columns.`,
    },
    gridRow: {
        regex: /(^|\s)grid-col($|[\s])/,
        errorMessage: '`GridRow` component must have `GridColumn` as child',
    },
    gridContainer: {
        regex: /(^|\s)grid-row($|[\s])/,
        errorMessage: '`GridContainer` component must have `GridRow` as child',
    },
};
