import { CounterColorVariants } from '@primitives/Counter/types';
import { TabPanelStates, TabPanelVariants } from '@primitives/TabPanel/types';

export const TAB_COUNTER_COLOR_MAP: Record<TabPanelStates, CounterColorVariants> = {
    danger: 'red',
    warning: 'orange',
};

export const DEFAULT_TAB_COUNTER_COLOR: CounterColorVariants = 'gray';

export const TAB_ACTIVE_COUNTER_COLOR: CounterColorVariants = 'blue';

export const TAB_PANEL_DEFAULT_VARIANT: TabPanelVariants = 'underline';
