import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const ArrowDownIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            width={svgWidth}
            height={svgHeight}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <mask
                id="mask0_7055_77252"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_7055_77252)">
                <path
                    d="M11.9936 14.9367C11.8605 14.9367 11.7356 14.9158 11.6191 14.8738C11.5025 14.8326 11.3943 14.7621 11.2944 14.6623L6.67464 10.0468C6.49152 9.86384 6.40428 9.63498 6.41294 9.36021C6.42093 9.08611 6.51649 8.85758 6.69962 8.67463C6.88274 8.49167 7.11581 8.40019 7.39883 8.40019C7.68184 8.40019 7.91491 8.49167 8.09804 8.67463L11.9936 12.5666L15.9142 8.64968C16.0973 8.46672 16.3264 8.37924 16.6014 8.38722C16.8758 8.39587 17.1045 8.49167 17.2877 8.67463C17.4708 8.85758 17.5624 9.09044 17.5624 9.37319C17.5624 9.65594 17.4708 9.88879 17.2877 10.0717L12.6929 14.6623C12.593 14.7621 12.4848 14.8326 12.3682 14.8738C12.2517 14.9158 12.1268 14.9367 11.9936 14.9367Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default React.memo(ArrowDownIcon);
