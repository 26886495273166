import React from 'react';
import cx from 'classnames';
import Typography from '@primitives/Typography';
import { switchSizeToLabelVariant } from './constants';
import { SwitchProps } from './types';
import styles from './Switch.module.scss';
import colors from '../../styles/colors.module.scss';
import { v4 as uuidv4 } from 'uuid';

const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
    (
        {
            variant = 'primary',
            switchSize = 'medium',
            label = '',
            hintText = '',
            disabled = false,
            id = uuidv4(),
            ...restProps
        },
        ref,
    ) => {
        const switchWrapperStyles = cx(
            styles['switch-wrapper'],
            styles[variant],
            styles[switchSize],
            {
                [styles['disabled']]: disabled,
            },
        );

        const renderText = (): JSX.Element | false => {
            return (
                label !== '' && (
                    <div className={styles['text-wrapper']}>
                        <Typography
                            variant={switchSizeToLabelVariant[switchSize]}
                            color={colors['--navi-color-gray-c2']}
                        >
                            {label}
                        </Typography>
                        {hintText !== '' && (
                            <Typography variant="p4" color={colors['--navi-color-gray-c3']}>
                                {hintText}
                            </Typography>
                        )}
                    </div>
                )
            );
        };

        return (
            <div className={switchWrapperStyles}>
                <div>
                    <input type="checkbox" id={id} ref={ref} disabled={disabled} {...restProps} />
                    <label htmlFor={id} className={styles['switch']} data-testid="switch"></label>
                </div>
                {renderText()}
            </div>
        );
    },
);

Switch.displayName = 'Switch';

export default Switch;
