import React from 'react';
import { TypeOptions } from '../types';
import { CloseIcon } from '@icons';
import { IconProps } from '@icons/types';

export interface CloseButtonProps {
    closeToast: (e: React.MouseEvent<HTMLElement>) => void;
    type: TypeOptions;
    isColored?: boolean;
}

export function CloseButton({ closeToast, isColored, type }: CloseButtonProps) {
    const iconProps: IconProps = {
        size: 'xs',
    };
    if (isColored && type !== 'default') {
        iconProps.color = 'white';
    }

    return (
        <span
            onClick={(e) => {
                e.stopPropagation();
                closeToast(e);
            }}
        >
            <CloseIcon {...iconProps} />
        </span>
    );
}
