import { TagColors, TagSizes, TagVariants } from './types';
import styles from './Tag.module.scss';
import React from 'react';

export const getTagVariantStyle = (color: TagColors, variant: TagVariants) => {
    if (color !== 'primary' && variant === 'transparent')
        return styles[`tag--${color}--${variant}`];
    return '';
};

export const getTagTextColor = (color: TagColors, variant: TagVariants) => {
    if (color === 'primary' || variant === 'transparent') return styles[`${color}Text`];
    return styles.opaqueText;
};

export const getTagSizeAdornmentStyle = (
    size: TagSizes,
    startAdornment: React.ReactNode,
    endAdornment: React.ReactNode,
) => {
    if (!startAdornment && !endAdornment) return styles[`tag--${size}--no-adornment`];
    if (startAdornment && !endAdornment) return styles[`tag--${size}--start-adornment`];
    if (endAdornment && !startAdornment) return styles[`tag--${size}--end-adornment`];
    return styles[`tag--${size}--both-adornment`];
};
