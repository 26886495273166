import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const CancelIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            width={svgWidth}
            height={svgHeight}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99996 1.6665C5.39163 1.6665 1.66663 5.3915 1.66663 9.99984C1.66663 14.6082 5.39163 18.3332 9.99996 18.3332C14.6083 18.3332 18.3333 14.6082 18.3333 9.99984C18.3333 5.3915 14.6083 1.6665 9.99996 1.6665ZM13.5833 13.5832C13.2583 13.9082 12.7333 13.9082 12.4083 13.5832L9.99996 11.1748L7.59163 13.5832C7.26663 13.9082 6.74163 13.9082 6.41663 13.5832C6.09163 13.2582 6.09163 12.7332 6.41663 12.4082L8.82496 9.99984L6.41663 7.5915C6.09163 7.2665 6.09163 6.7415 6.41663 6.4165C6.74163 6.0915 7.26663 6.0915 7.59163 6.4165L9.99996 8.82484L12.4083 6.4165C12.7333 6.0915 13.2583 6.0915 13.5833 6.4165C13.9083 6.7415 13.9083 7.2665 13.5833 7.5915L11.175 9.99984L13.5833 12.4082C13.9 12.7248 13.9 13.2582 13.5833 13.5832Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(CancelIcon);
