import React from 'react';
import cx from 'classnames';
import ArrowDownSolidIcon from '@icons/ArrowDownSolidIcon';
import Typography from '@primitives/Typography';
import { getNavItemLabelColor } from '../utils';
import { DropdownNavItemProps } from './types';
import styles from './DropdownNavItem.module.scss';

const DropdownNavItem: React.FC<DropdownNavItemProps> = (props) => {
    const { pathname } = window.location;
    const { navItem, index, dropdownExpanded } = props;
    const [showDropdownNavOptions, setShowDropdownNavOptions] = React.useState(dropdownExpanded);
    const { label, Icon, options } = navItem;

    const arrowIconStyles = cx(styles['arrow-icon'], {
        [styles['arrow-icon--with-dropdown-expanded']]: showDropdownNavOptions,
    });
    const contentColor = getNavItemLabelColor(showDropdownNavOptions);

    const renderOptions = (): JSX.Element | false => {
        return (
            showDropdownNavOptions && (
                <ul className={styles['nav-dropdown-options']} role="listbox">
                    {options.map((option, index) => {
                        const { label, route, handleNavigation } = option;
                        const isActive = pathname === route;

                        const optionStyles = cx(styles.option, {
                            [styles['option--active']]: isActive,
                        });

                        return (
                            <li
                                onClick={handleNavigation}
                                className={optionStyles}
                                role="option"
                                key={index}
                            >
                                <Typography variant="p4" color={getNavItemLabelColor(isActive)}>
                                    {label}
                                </Typography>
                            </li>
                        );
                    })}
                </ul>
            )
        );
    };

    return (
        <li key={label + index} role="listitem">
            <div
                onClick={() => setShowDropdownNavOptions((prevState) => !prevState)}
                className={styles['nav-dropdown-item']}
                data-testid="dropdown-nav-item"
            >
                <div className={styles['nav-dropdown-item-content']}>
                    <Icon size="md" color={contentColor} />
                    <Typography
                        variant="p3"
                        color={contentColor}
                        className={styles['nav-dropdown-item-content__label']}
                    >
                        {label}
                    </Typography>
                </div>
                <ArrowDownSolidIcon
                    width={10}
                    height={6.4}
                    color={contentColor}
                    className={arrowIconStyles}
                />
            </div>
            {renderOptions()}
        </li>
    );
};

export default DropdownNavItem;
