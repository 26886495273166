import React from 'react';
import { IconProps } from '../types';
import styles from '../Icons.module.scss';
import { getSvgDimensions } from '../utils';
import { DEFAULT_ICON_SIZE } from '../constants';

const AlertOutlineIcon: React.FC<IconProps> = ({
    size = DEFAULT_ICON_SIZE,
    width,
    height,
    color = styles.naviIconDefaultColor,
    ...restProps
}) => {
    const { svgHeight, svgWidth } = getSvgDimensions({ height, width, size });
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={svgWidth}
            height={svgHeight}
            fill="none"
            viewBox="0 0 24 24"
            {...restProps}
        >
            <path
                fill={color}
                fillRule="evenodd"
                d="M12 7c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1s-1-.45-1-1V8c0-.55.45-1 1-1zm-.01-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm1-3h-2v-2h2v2z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};

export default React.memo(AlertOutlineIcon);
