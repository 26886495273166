import { DEFAULT_ICON_SIZE, SIZE_MAP } from './constants';
import { IconSize, SvgSize } from './types';

export const getSvgDimensions = (sizeProps: SvgSize) => {
    const { width, height, size } = sizeProps;
    let svgHeight, svgWidth;
    if (width && !height) svgHeight = svgWidth = width;
    else if (!width && height) svgHeight = svgWidth = height;
    else if (width && height) {
        svgHeight = height;
        svgWidth = width;
    } else {
        const { width, height } = getSvgWidthHeightBasedOnSize(size);
        svgHeight = height;
        svgWidth = width;
    }

    return { svgHeight, svgWidth };
};

const getSvgWidthHeightBasedOnSize = (size: IconSize | undefined) => {
    if (!size) return SIZE_MAP[DEFAULT_ICON_SIZE];
    return SIZE_MAP[size];
};
