import { AvatarSizeVariants, AvatarVariants } from './types';
import styles from './Avatar.module.scss';

export const getAvatarVariantStyle = (variant: AvatarVariants) => styles[`avatar--${variant}`];

export const getAvatarWrapperStyle = (isImage: boolean) =>
    !isImage ? styles['avatar--children'] : null;

export const getAvatarSizeStyle = (sizeVariant: AvatarSizeVariants) => {
    return styles[`avatar--${sizeVariant}`];
};

export const getStatusWrapperStyle = (size: AvatarSizeVariants) => {
    return styles[`status-wrapper--${size}`];
};
