import React from 'react';
import cx from 'classnames';
import BorderedInput from '@primitives/BorderedInput';
import BottomSheet from '@primitives/BottomSheet';
import Typography from '@primitives/Typography';
import { SearchSelectPopUpProps } from './types';
import styles from './SearchSelectPopUp.module.scss';
import SelectSearchResultList from '@primitives/SelectSearchResultList';
import { HelperTextVariantType } from '@primitives/BorderedInput/types';

const SearchSelectPopUp: React.FC<SearchSelectPopUpProps> = ({
    title = '',
    open,
    onClose,
    handleValueSelection,
    onSearchChange = undefined,
    results,
}) => {
    const [searchValue, setSearchValue] = React.useState('');
    const [containerClassList, setContainerClassList] = React.useState<string>(
        styles['select-header'],
    );

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);
        onSearchChange && onSearchChange(value);
    };

    const handleBottomSheetClose = () => {
        onClose();
        setSearchValue('');
        onSearchChange && onSearchChange('');
    };

    const getErrorDetails = (): HelperTextVariantType => {
        if (searchValue !== '' && results.length === 0) {
            return 'No search results found :(';
        }
        return false;
    };

    const renderTitle = (): JSX.Element | false => {
        return (
            title !== '' && (
                <Typography variant="h2" className={styles['select-title']}>
                    {title}
                </Typography>
            )
        );
    };

    const renderSearch = (): JSX.Element | undefined => {
        return (
            onSearchChange && (
                <BorderedInput
                    value={searchValue}
                    onChange={handleOnChange}
                    inputSize="full-width"
                    error={getErrorDetails()}
                    containerClassName={styles['search-container']}
                />
            )
        );
    };

    const fixedHeightForPopUp = onSearchChange ? { fixedHeight: 75 } : {};

    const handleBottomSheetScroll = (event: any): void => {
        setContainerClassList(
            cx(styles['select-header'], {
                [styles['select-header--with-shadow']]: event.target.scrollTop !== 0,
            }),
        );
    };

    return (
        <BottomSheet
            open={open}
            onClose={handleBottomSheetClose}
            showCloseIcon
            usePadding={false}
            onScroll={handleBottomSheetScroll}
            {...fixedHeightForPopUp}
        >
            <div className={styles['select-wrapper']}>
                <div className={containerClassList}>
                    {renderTitle()}
                    {renderSearch()}
                </div>
                <SelectSearchResultList results={results} onOptionSelect={handleValueSelection} />
            </div>
        </BottomSheet>
    );
};

export default SearchSelectPopUp;
