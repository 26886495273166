import React from 'react';
import cx from 'classnames';
import { TypographyProps, TypographyWrapperProps } from './types';
import { getTypographyVariantClass } from './utils';
import styles from './Typography.module.scss';

const Wrapper = (props: TypographyWrapperProps) => {
    const { as = 'div', children, className = '', color, style, role } = props;
    return React.createElement(as, { className, style: { ...style, color }, role }, children);
};

const Typography: React.FC<TypographyProps> = (props) => {
    const { variant, children, as, color, className, style, role } = props;
    const typographyClass = cx(getTypographyVariantClass(variant), styles.typography, className);

    return (
        <Wrapper as={as} className={typographyClass} color={color} style={style} role={role}>
            {children}
        </Wrapper>
    );
};

export default Typography;
