import { FontType } from '@primitives/Typography/types';
import { BorderedInputSizeVariant } from './types';

export enum inputStates {
    default = 'default',
    filled = 'filled',
    active = 'active',
    error = 'error',
    success = 'success',
    disabled = 'disabled',
}

export enum BorderedInputClasses {
    base = 'bordered-input',
    wrapper = 'bordered-input__input-wrapper',
    inputContainer = 'bordered-input__input-wrapper--container',
    leftAdornment = 'bordered-input__input-wrapper--left-adornment',
    rightAdornment = 'bordered-input__input-wrapper--right-adornment',
    labelWrapper = 'bordered-input__input-label-wrapper',
    label = 'bordered-input__input-label-wrapper--label',
    required = 'bordered-input__input-label-wrapper--required',
    labelIcon = 'bordered-input__input-label-wrapper--icon',
    helperTextWrapper = 'bordered-input__helper-text-wrapper',
    helperTextIcon = 'bordered-input__helper-text-wrapper--icon',
    hintTextWrapper = 'bordered-input__hint-text-wrapper',
    hintTextMsgs = 'bordered-input__hint-text-wrapper-text-msgs',
    hintTextIcon = 'bordered-input__hint-text-wrapper-text-msgs--icon',
}

export enum HelperTextVariants {
    error = 'error',
    success = 'success',
}

export const inputSizeToFontType: Record<BorderedInputSizeVariant, FontType> = {
    small: 'p4',
    medium: 'p3',
    large: 'p2',
    'full-width': 'p2',
};

export const otherTextToFontType: Record<BorderedInputSizeVariant, FontType> = {
    small: 'p5',
    medium: 'p4',
    large: 'p3',
    'full-width': 'p2',
};
